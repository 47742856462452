import { CurrentBestRate } from '@/types'
import Bignumber from '@/utils/bignumber'

export function groupTokenAmountFromBestRateData(bestRate: CurrentBestRate[]) {
  const groupTokenAmount = bestRate.reduce((acc: any, cur: any) => {
    acc.amountIn = Bignumber(acc.amountIn).plus(cur.amountIn).toString(10)
    acc.amountOut = Bignumber(acc.amountOut).plus(cur.amountOut).toString(10)
    acc.routes.push(cur.routeIndex)
    return acc
  }, { amountIn: Bignumber('0'), amountOut: Bignumber('0'), routes: [] })
  return groupTokenAmount
}
