import { getAddress } from '@ethersproject/address'
import bignumber from '@/utils/bignumber'
import { APP_NAME } from '@/constants'
import { Token, UserLocalStorage, ErrorHTMLImageElement } from '@/types'
import Rollbar, { Level } from 'rollbar'

const currentTimestamp = () => new Date().getTime()

export const rollbar = new Rollbar({
  accessToken: process.env.VUE_APP_ROLLBAR_POST_CLIENT_ITEM_TOKEN,
  environment: process.env.VUE_APP_ROLLBAR_ENV || 'production',
  ignoredMessages: ['Failed to fetch']
})

export function shortenHex(value: string, format = 5): string {
  if (!value) return ''
  const zeroXLength = 2
  const first = value.substr(0, format + zeroXLength)
  const last = value.substr(value.length - format, value.length)
  return `${first}...${last}`
}

export function isAddress(search: string): string | false {
  try {
    return getAddress(search)
  } catch {
    return false
  }
}

export function filterTokens(tokens: Token[], search: string): Token[] {
  if (search.length === 0) return tokens

  const searchingAddress = isAddress(search)

  if (searchingAddress) {
    return tokens.filter((token) => token.address.toUpperCase() === searchingAddress.toUpperCase())
  }

  const lowerSearchParts = search
    .toLowerCase()
    .split(/\s+/)
    .filter((s) => s.length > 0)

  if (lowerSearchParts.length === 0) {
    return tokens
  }

  const matchesSearch = (s: string): boolean => {
    const sParts = s
      .toLowerCase()
      .split(/\s+/)
      .filter((str) => str.length > 0)

    return lowerSearchParts.every((p) => p.length === 0 || sParts.some((sp) => sp.startsWith(p) || sp.endsWith(p)))
  }

  return tokens.filter((token) => {
    const { symbol, name } = token

    return (symbol && matchesSearch(symbol)) || (name && matchesSearch(name))
  })
}

function balanceComparator(balanceA?: string, balanceB?: string) {
  if (balanceA && balanceB) {
    return bignumber(balanceA).gt(bignumber(balanceB)) ? -1 : bignumber(balanceA).eq(bignumber(balanceB)) ? 0 : 1
  }
  if (balanceA && bignumber(balanceA).gt('0')) {
    return -1
  }
  if (balanceB && bignumber(balanceB).gt('0')) {
    return 1
  }
  return 0
}

export function getTokenComparator(
  balances: { [tokenAddress: string]: string },
  tokenA: Token,
  tokenB: Token
): number {
  // sort by balances
  const balanceA = balances[tokenA.address]
  const balanceB = balances[tokenB.address]
  const balanceComp = balanceComparator(balanceA, balanceB)
  if (balanceComp !== 0) return balanceComp

  if (tokenA.symbol && tokenB.symbol) {
    // sort by symbol
    return tokenA.symbol.toLowerCase() < tokenB.symbol.toLowerCase() ? -1 : 1
  }
  return tokenA.symbol ? -1 : tokenB.symbol ? -1 : 0
}

export function precisionNumber(value: string | number, precision = 6) {
  return bignumber(value).precision(precision)
}

export function toFixedDecimal(value: any, format = 2) {
  if (typeof value === 'string' || typeof value === 'number') {
    value = bignumber(value)
  }
  if (!value || value.isZero()) return 0
  return value.toFormat(format)
}

export function getTokenImage(symbol: string | null, address: string | null) {
  try {
    if (symbol) {
      return require(`@/assets/img/coins/${symbol.toLowerCase()}.png`)
    }
  } catch (error) {
    if (address) {
      return `https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/smartchain/assets/${getAddress(address)}/logo.png`
    }
  }
}

export function handleTokenImageError(e: ErrorHTMLImageElement): void {
  e.target.src = require('@/assets/svg/icon-unknow-token.svg')
}

export const numberWithCommas = (number: number | string, format = { groupSeparator: ',', groupSize: 3, decimalSeparator: '.' }) => {
  return bignumber(number).toFormat(format).toString()
}

// add 25%
export function calculateGasMargin(value: string | number): string {
  return bignumber(value).multipliedBy('1.25').toFixed(0)
}

export function loadUserStorage(): UserLocalStorage {
  const localStorageName = `${APP_NAME}.user`
  let newLocalStorageData = {} as UserLocalStorage
  const tempLocalStorage = localStorage.getItem(localStorageName)
  if (tempLocalStorage) {
    const tempData = JSON.parse(tempLocalStorage)
    newLocalStorageData = { ...tempData }
  }
  if (!newLocalStorageData.hasOwnProperty('timestamp')) {
    const current = currentTimestamp()
    newLocalStorageData.createdTimestamp = current
    newLocalStorageData.lastUpdateTimestamp = current
  }
  if (!newLocalStorageData.hasOwnProperty('tokens')) {
    newLocalStorageData.tokens = {}
  }
  return newLocalStorageData
}

function saveUserStorage(newUserLocalStorage: UserLocalStorage) {
  const localStorageName = `${APP_NAME}.user`
  newUserLocalStorage.lastUpdateTimestamp = currentTimestamp()
  localStorage.setItem(localStorageName, JSON.stringify(newUserLocalStorage))
}

export function addTokenInlocalStorage(tokenData: Token) {
  const tempData = loadUserStorage()
  tempData.tokens[tokenData.address] = tokenData
  saveUserStorage(tempData)
}

export function removeTokenInlocalStorage(tokenAddress: string) {
  const tempData = loadUserStorage()
  delete tempData.tokens[tokenAddress]
  saveUserStorage(tempData)
}

export function logToRollbar(level: Level, message: string | null, args: any | null) {
  try {
    const argsExpected = []
    message ? argsExpected.push(message) : null
    args ? argsExpected.push(args) : null
    rollbar[level](...argsExpected)
  } catch {
    console.log('rollbar error')
  }
}
