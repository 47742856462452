import { FarmConfig } from '@/types'

const farms: FarmConfig[] = [
  {
    pid: 0,
    lpSymbol: 'WAD',
    lpAddresses: '0x0fEAdcC3824E7F3c12f40E324a60c23cA51627fc'
  },
  {
    pid: 1,
    lpSymbol: 'WAD - BNB  LP',
    lpAddresses: '0xDc683Adb914EdF91df4A36c33EE4f59ca41bC263'
  },
  {
    pid: 2,
    lpSymbol: 'BNB - BUSD LP',
    lpAddresses: '0xCf643C4B9DBf42239aa00e23A0570c90d517E6dC'
  },
  {
    pid: 3,
    lpSymbol: 'BTCB - BNB LP',
    lpAddresses: '0x1b1675A97b2f62B568569ebD349E88A04DdE8586'
  },
  {
    pid: 4,
    lpSymbol: 'ETH - BNB LP',
    lpAddresses: '0x8485c5f255FF30AafaB0030329e508BD8dDE11c5'
  },
  {
    pid: 5,
    lpSymbol: 'USDT - BUSD LP',
    lpAddresses: '0x087d69B97a6dF4FB37E4E93A31752008223A6C19'
  },
  {
    pid: 6,
    lpSymbol: 'CAKE - BNB LP',
    lpAddresses: '0xF06ebE478d73c8c935e72C43e8F71C592d6E600f'
  },
  {
    pid: 7,
    lpSymbol: 'WAD - BUSD LP',
    lpAddresses: '0xc95b1750043fce5dfcc8539835ea3830ec002a89'
  }
]

export default farms
