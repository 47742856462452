








































































import { Component, Mixins } from 'vue-property-decorator'
import { AbstractWeb3ConnectorView } from '@/features/Web3Connector/abstractView'
import { WalletProvider } from '@/features/Web3Connector/types/Wallet'
import { Modal } from './modal'

@Component
export default class ConnectWalletButton extends Mixins(
  AbstractWeb3ConnectorView,
  Modal
) {
  async selectedWallet(walletProvider: WalletProvider) {
    await this.connect(walletProvider)
    this.isModalOpen = false
  }
}
