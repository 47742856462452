import { Component, Watch, Vue } from 'vue-property-decorator'

@Component
export class Modal extends Vue {
  isModalOpen = false
  windowWidth = window.innerWidth
  modalPosition = 'center'

  onWindowResize() {
    this.windowWidth = window.innerWidth
  }

  mounted() {
    window.addEventListener('resize', this.onWindowResize)
  }

  destroyed() {
    window.removeEventListener('resize', this.onWindowResize)
  }

  created() {
    this.handleModalPosition()
  }

  showModal() {
    this.isModalOpen = true
  }

  closeModal() {
    this.isModalOpen = false
  }

  handleModalPosition() {
    if (this.isMobile) {
      this.modalPosition = 'bottom'
    } else {
      this.modalPosition = 'center'
    }
  }

  get isMobile() {
    return this.windowWidth <= 768
  }

  @Watch('isMobile')
  onIsMobileChange() {
    this.handleModalPosition()
  }
}
