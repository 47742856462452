


























































































import { Component, Vue } from 'vue-property-decorator'
import ConnectWalletButton from './components/ConnectWalletButton.vue'
import ContactInfo from '../../components/ContactInfo.vue'

@Component({
  components: {
    ConnectWalletButton,
    ContactInfo
  }
})
export default class Header extends Vue {
  isOpen = false
  drawer() {
    this.isOpen = !this.isOpen
  }
}
