









































import { Component } from 'vue-property-decorator'
import Toast from 'primevue/toast'
import Header from '@/features/Header/index.vue'
import ContactInfo from '@/components/ContactInfo.vue'
import ConnectWalletButtonOnMobile from '@/features/ConnectWalletButtonOnMobile/index.vue'
import AutoConnectView from '@/features/Web3Connector/components/AutoConnect'

@Component({
  components: {
    Header,
    ContactInfo,
    Toast,
    ConnectWalletButtonOnMobile
  }
})
export default class Home extends AutoConnectView {}
