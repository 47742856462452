import { Module, VuexModule, MutationAction, getModule } from 'vuex-module-decorators'
import { stateDecoratorFactory } from 'vuex-module-decorators-state'
import { WalletProvider } from '../types'
import { Network } from '@/features/Web3Connector/types/Web3'
import Web3 from 'web3'
import Store from '@/store'
import { Contract } from 'ethers'
import { DEFAULT_GAS_PRICE } from '@/constants'

const WALLET_PROVIDER_CACHE_KEY = process.env.WALLET_PROVIDER_CACHE_KEY || 'WARDEN.current-wallet-connector'

@Module({
  namespaced: true,
  dynamic: true,
  store: Store,
  name: 'web3Connector'
})
export class Web3Connector extends VuexModule {
  walletProvider: WalletProvider | null = localStorage.getItem(WALLET_PROVIDER_CACHE_KEY) as WalletProvider || null
  web3: Web3 | null = null
  userAddress: string | null = null

  // Wallet Info
  walletName: string | null = null
  walletType: string | null = null
  walletLogo: string | null = null

  provider: any | null = null

  network: Network | 'disconnected' = 'disconnected'
  isWalletConnected = false
  isCorrectNetwork = false
  gasPrice: string = DEFAULT_GAS_PRICE

  wardenSwap: Contract | null = null
  bestRateQuery: Contract | null = null

  get userDefaultWallet(): string {
    return this.userAddress ?? 'unknown'
  }

  // 'events' and 'conferences' are replaced by returned object
  // whose shape must be `{events: [...], conferences: [...] }`
  @MutationAction({ mutate: ['walletProvider'] })
  async setWalletProvider(walletProvider: WalletProvider | null) {
    localStorage.setItem(WALLET_PROVIDER_CACHE_KEY, walletProvider ?? '')
    return { walletProvider }
  }

  @MutationAction({ mutate: ['web3'] })
  async setWeb3(web3: Web3 | null) {
    return { web3 }
  }

  @MutationAction({ mutate: ['userAddress'] })
  async setUserAddress(userAddress: string | null) {
    return { userAddress }
  }

  @MutationAction({ mutate: ['walletName'] })
  async setWalletName(walletName: string | null) {
    return { walletName }
  }

  @MutationAction({ mutate: ['walletType'] })
  async setWalletType(walletType: string | null) {
    return { walletType }
  }

  @MutationAction({ mutate: ['walletLogo'] })
  async setWalletLogo(walletLogo: string | null) {
    return { walletLogo }
  }

  @MutationAction({ mutate: ['provider'] })
  async setProvider(provider: any | null) {
    return { provider }
  }

  @MutationAction({ mutate: ['isCorrectNetwork'] })
  async setIsCorrectNetwork(isCorrectNetwork: boolean) {
    return { isCorrectNetwork }
  }

  @MutationAction({ mutate: ['network'] })
  async setNetwork(network: Network | 'disconnected') {
    return { network }
  }

  @MutationAction({ mutate: ['isWalletConnected'] })
  async setIsWalletConnected(isWalletConnected: boolean) {
    return { isWalletConnected }
  }

  @MutationAction({ mutate: ['wardenSwap'] })
  async setWardenSwap(wardenSwap: Contract | null) {
    return { wardenSwap }
  }

  @MutationAction({ mutate: ['bestRateQuery'] })
  async setBestRateQuery(bestRateQuery: Contract | null) {
    return { bestRateQuery }
  }

  @MutationAction({ mutate: ['gasPrice'] })
  async setGasPrice(gasPrice: string) {
    return { gasPrice }
  }
}

export const web3ConnectorModule = getModule(Web3Connector)
export const Web3ConnectorState = stateDecoratorFactory(web3ConnectorModule)
