



































































































import { AbstractWeb3ConnectorView } from '@/features/Web3Connector/abstractView'
import { AbstractSwapView } from '@/features/Swap/abstractView'
import { Component, Mixins, Watch } from 'vue-property-decorator'
import { BSC_SCAN_URL } from '@/constants'
import { Modal } from './modal'
import { DisplayWalletProvider, Token } from '@/types'
import { logToRollbar } from '@/utils/helper'
import Bignumber from '@/utils/bignumber'

@Component({
  name: 'UserWalletModal'
})

export default class UserWalletModal extends Mixins(AbstractWeb3ConnectorView, AbstractSwapView, Modal) {
  DisplayWalletProvider = DisplayWalletProvider

  isModalOpen = false
  unclaimed = ''
  wadTokenBalanceOfUser = ''
  allWadToken = ''

  async handleDisconnect() {
    await this.disconnect()
    this.isModalOpen = false
  }

  openLinkBSCNewTab() {
    const uri = `${BSC_SCAN_URL}/address/${this.userAddress}`
    window.open(uri, '_blank')
  }

  handleAddWardenToken() {
    try {
      this.addWardenTokenInMetaMask()
    } catch (error) {
      logToRollbar('error', 'Error: Function addWardenTokenInMetaMask', error)
      console.log('Error: Function addWardenTokenInMetaMask')
    }
  }

  @Watch('isModalOpen')
  async checkWADPrice(val: boolean) {
    if (val) {
      this.getWADPrice()
      this.getWardenFarmInfo()
        .then((wardenFarmInfo: any) => {
          this.unclaimed = wardenFarmInfo?.unclaimed || ''
          this.wadTokenBalanceOfUser = wardenFarmInfo?.wadTokenBalanceOfUser || ''
          this.allWadToken = wardenFarmInfo?.allWadToken || ''
        })
    }
  }

  get displayWadTokenPriceUsd() {
    if (Bignumber(this.wadTokenPriceUsd).isZero()) {
      return ''
    }
    return this.wadTokenPriceUsd
  }

  get isHasWADBalanceData(): boolean {
    if (this.tokensBalance.hasOwnProperty(this.wadTokenData.address)) {
      return true
    }
    return false
  }

  get wadTokenData(): Token {
    return this.allToken.find((token: Token) => token.symbol === 'WAD') as Token
  }
}
