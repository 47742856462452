


















































import { Component, Mixins, Watch, Emit, PropSync } from 'vue-property-decorator'
import { AbstractSwapView } from '@/features/Swap/abstractView'
import { Modal } from './modal'
import { filterTokens, isAddress, logToRollbar } from '@/utils/helper'
import { Token } from '@/types'
import TokenDetailWrap from '@/components/TokenDetailWrap.vue'
import AcceptCustomToken from '@/components/AcceptCustomToken.vue'

// TODO: data interface
// amount?: number;
// usdValue?: number;

@Component({
  name: 'TokenListModal',
  components: {
    TokenDetailWrap,
    AcceptCustomToken
  }
})
export default class TokenListModal extends Mixins(AbstractSwapView, Modal) {
  isModalOpen = false
  windowWidth = window.innerWidth
  modalPosition = 'center'
  expectedToken: Token[] = []
  searchToken = ''
  isCustomToken = false
  isAcceptCustomToken = false

  @PropSync('selectedToken', { type: Object }) syncedSelectedToken!: object

  onSearchTokenInput(val: any) {
    this.searchToken = val.target.value
  }

  playSoundEffect() {
    const audio = document.getElementById('bgMusic') as HTMLAudioElement
    if (audio) {
      audio.play()
    }
  }

  handleContinueBtn(token: Token) {
    this.isAcceptCustomToken = true
    logToRollbar('info', 'User accept import token.', {
      senderAddress: this.userAddress,
      tokenAddress: token.address,
      tokenName: token.name,
      tokenSymbol: token.symbol
    })
  }

  async showModal() {
    this.isModalOpen = true
    await this.getTokensBalance()
  }

  created() {
    this.onSearchTokenChange()
  }

  @Emit()
  handleSelectedToken(token: Token) {
    this.playSoundEffect()
    this.handleTokenToVuexState(token)
    this.isModalOpen = false
  }

  @Watch('searchToken', { immediate: true })
  async onSearchTokenChange() {
    this.isCustomToken = false
    const result = filterTokens(this.allToken, this.searchToken)
    this.expectedToken = result
    if (!result.length && isAddress(this.searchToken)) {
      try {
        const data = await this.getTokenInfo(this.searchToken) as Token
        // TODO: fix
        this.isCustomToken = true
        this.expectedToken = [data]
        this.getTokensBalance([data])
      } catch (error) {
        console.error(error)
      }
    }
  }

  @Watch('allToken', { immediate: true })
  async onAllTokenChange(val: Token[], oldVal: Token[]) {
    // Update expectedToken when user click decrease token
    if (val?.length !== oldVal?.length && this.expectedToken.length !== 1) {
      this.expectedToken = this.allToken
    }
  }

  @Watch('isModalOpen')
  resetSearchToken(val: boolean) {
    if (!val) {
      this.searchToken = ''
    } else {
      this.isCustomToken = false
      this.isAcceptCustomToken = false
    }
  }
}
