






































































































import { Component, Ref, Watch, Mixins } from 'vue-property-decorator'
import TokenInput from '@/components/TokenInput.vue'
import PercentageSelector from '@/components/PercentageSelector.vue'
import BestRateRoutingBox from '@/components/BestRateRoutingBox.vue'
import ConnectWalletModal from '@/components/ConnectWalletModal.vue'
import ConfirmSwapModal from '@/components/ConfirmSwapModal.vue'
import WaitingConfirmSwapModal from '@/components/WaitingConfirmSwapModal.vue'
import TotalTradingVolumebox from '@/components/TotalTradingVolumebox.vue'
import BannerCarousel from '@/components/BannerCarousel.vue'
import TokenImportModal from '@/components/TokenImportModal.vue'
import { TransactionResponse } from '@ethersproject/providers'
import { AbstractWeb3ConnectorView } from '@/features/Web3Connector/abstractView'
import { AbstractSwapView } from '@/features/Swap/abstractView'
import { DEFAULT_TOKEN_A_INPUT_ADDRESS, WARDEN_TITLE_WAB_PAGE, MIN_BNB_SWAP } from '@/constants'
import bignumber from '@/utils/bignumber'
import { isAddress, logToRollbar } from '@/utils/helper'
import {
  BestRateButtonStatus,
  TokenInput as TokenInputType,
  ApprovalState,
  BestRateQueryState,
  BestRateQuerySide,
  Token,
  TradeState
} from '@/types'

enum DisplayBtnStatus {
  CONNECT_WALLET = 'Connect Wallet',
  ENTER_TOKEN_AMOUNT = 'Enter Token Amount',
  BALANCE_IS_NOT_ENOUGH = 'Your balance is not enough',
  SELECT_A_TOKEN = 'Select a token',
  UNLOCK_TOKEN = 'Unlock Token',
  UNLOCK_TOKEN_LOADING = 'Unlock Token',
  BEST_RATE_QUERY_LOADING = 'Waiting ...',
  SWAP = 'Swap',
  WRONG_NETWORK = 'Wrong Network',
  UNVALUABLE = 'Unvaluable',
  FAIL = 'Find best rate failed please try again'
}

@Component({
  name: 'BestRateSwap',
  components: {
    TokenInput,
    PercentageSelector,
    BestRateRoutingBox,
    ConnectWalletModal,
    ConfirmSwapModal,
    WaitingConfirmSwapModal,
    TotalTradingVolumebox,
    BannerCarousel,
    TokenImportModal
  }
})
export default class BestRateSwap extends Mixins(AbstractWeb3ConnectorView, AbstractSwapView) {
  @Ref() readonly ConnectWalletModal!: ConnectWalletModal
  @Ref() readonly ConfirmSwapModal!: ConfirmSwapModal
  @Ref() readonly WaitingConfirmSwapModal!: WaitingConfirmSwapModal
  @Ref() readonly PercentageSelector!: PercentageSelector
  @Ref() readonly TokenImportModalForTokenA!: TokenImportModal
  @Ref() readonly TokenImportModalForTokenB!: TokenImportModal

  displayBalanceTokenA = '0'
  displayBalanceTokenB = '0'
  DisplayBtnStatus = DisplayBtnStatus
  displayTextOnbutton = DisplayBtnStatus.CONNECT_WALLET
  errorMessageModal = ''

  created() {
    this.handleUpdateAllToken()
    const defaultTokenAData = this.allToken.find((token: Token) => token.address === DEFAULT_TOKEN_A_INPUT_ADDRESS) as Token
    setTimeout(() => this.getTokensBalance([defaultTokenAData]), 5000)
    this.fetchDataEveryTime()
  }

  async watchBestRate() {
    if (this.lastBestRateQuerySide === BestRateQuerySide.FROM_A_TO_B) {
      this.watchBestRateTokenAToTokenB()
    } else if (this.lastBestRateQuerySide === BestRateQuerySide.FROM_B_TO_A) {
      // TODO: close feature
      // this.watchBestRateTokenBToTokenA()
    }
  }

  async handleDefaultToken() {
    if (!this.bestRateQuery) {
      return
    }
    // call function when best rate query is exist
    const query = this.$route.query
    if (query.hasOwnProperty('from') && isAddress(query.from as string)) {
      this.handleTokenInput('tokenA', query.from as string)
    } else if (DEFAULT_TOKEN_A_INPUT_ADDRESS) {
      this.handleTokenInput('tokenA', DEFAULT_TOKEN_A_INPUT_ADDRESS)
    }
    if (query.hasOwnProperty('to') && isAddress(query.to as string)) {
      this.handleTokenInput('tokenB', query.to as string)
    }
  }

  handleTokenInput(type: 'tokenA' | 'tokenB', tokenAddress: string) {
    const tokenData = this.allToken.find((token: Token) => token.address === tokenAddress) as Token
    if (!tokenData) {
      switch (type) {
        case 'tokenA':
          this.TokenImportModalForTokenA.showModalWithTokenAddress(type, tokenAddress)
          break
        case 'tokenB':
          this.TokenImportModalForTokenB.showModalWithTokenAddress(type, tokenAddress)
          break
      }
    } else {
      const data: TokenInputType = { address: tokenData.address, symbol: tokenData.symbol }
      this.setTokenInput(type, data)
      this.getTokensBalance([tokenData])
    }
  }

  async fetchWADTokenPrice() {
    await this.getWADPrice()
    if (!bignumber(this.wadTokenPriceUsd).isZero()) {
      document.title = `${WARDEN_TITLE_WAB_PAGE} - $${bignumber(this.wadTokenPriceUsd).toFixed(3)}`
    }
  }

  fetchTokenInputBalance() {
    const listOfTokenData = [] as Token[]
    if (this.tokenAInput.hasOwnProperty('address')) {
      const tokenAData = this.allToken.find((token: Token) => token.address === this.tokenAInput.address) as Token
      if (Object.keys(tokenAData).length) {
        listOfTokenData.push(tokenAData)
      }
    }
    if (this.tokenBInput.hasOwnProperty('address')) {
      const tokenBData = this.allToken.find((token: Token) => token.address === this.tokenBInput.address) as Token
      if (Object.keys(tokenBData).length) {
        listOfTokenData.push(tokenBData)
      }
    }
    if (listOfTokenData.length) {
      this.getTokensBalance(listOfTokenData)
    }
  }

  fetchDataEveryTime() {
    this.fetchWADTokenPrice()
    setInterval(() => {
      this.fetchWADTokenPrice()
      this.watchBestRate()
      this.fetchTokenPrice()
      this.fetchTokenInputBalance()
    }, 1000 * 10) // 10s
  }

  handleSelectedPercentage(percentage: number) {
    if (this.tokenAInput?.address && this.tokensBalance[this.tokenAInput.address]) {
      const tokenAData = this.allToken.find((token: Token) => token.address === this.tokenAInput.address)
      // @ts-ignore
      const amountInWei = bignumber(this.tokensBalance[this.tokenAInput.address]).toWei(tokenAData.decimals)
      const resultInWei = bignumber(amountInWei).multipliedBy(bignumber(percentage).div(100)).toFixed(0)
      // @ts-ignore
      const resultInBase = bignumber(resultInWei).toBase(tokenAData.decimals).toString(10)
      const tokenAmount = this.getTokenAmountCanBeSwap(resultInBase, this.tokensBalance[this.tokenAInput.address])
      this.setTokenInputAmount('tokenA', tokenAmount)
    }
  }

  getTokenAmountCanBeSwap(tokenAmount: string, tokenBalance: string): string {
    const tokenBNBData = this.allToken.find((token: Token) => token.symbol === 'BNB') as Token
    if (this.tokenAInput.address === tokenBNBData.address) {
      if (bignumber(tokenAmount).isEqualTo(tokenBalance) && bignumber(tokenAmount).isGreaterThan(MIN_BNB_SWAP)) {
        return bignumber(tokenAmount).minus(MIN_BNB_SWAP).toString(10)
      } else if (bignumber(bignumber(tokenAmount).plus(MIN_BNB_SWAP)).isGreaterThan(tokenBalance)) {
        return '0'
      }
    }
    return tokenAmount
  }

  handleEventSubmitBtn(): void {
    switch (this.submitBtnStatus) {
      case 'CONNECT_WALLET':
        this.ConnectWalletModal.showModal()
        break
      case 'UNLOCK_TOKEN': {
        this.approveToken(this.tokenAInput.address)
        break
      }
      case 'SWAP':
        this.ConfirmSwapModal.showModal()
        break
    }
  }

  async handleConfirmSwap() {
    try {
      this.WaitingConfirmSwapModal.showModal()
      const transactionResponse = await this.trade() as TransactionResponse
      this.WaitingConfirmSwapModal.setTxHash(transactionResponse.hash)
      await this.waitTransactionConfirm(transactionResponse)
    } catch (error) {
      logToRollbar('error', 'Error: Function handleConfirmSwap', error)
      this.errorMessageModal = error.message
      console.error(error)
    }
  }

  get submitBtnStatus(): BestRateButtonStatus {
    if (!this.isWalletConnected) {
      return 'CONNECT_WALLET'
    } else if (this.bestRateQueryState === BestRateQueryState.UNVALUABLE) {
      return 'UNVALUABLE'
    } else if (this.bestRateQueryState === BestRateQueryState.FAIL) {
      return 'FAIL'
    } else if (this.approvalState === ApprovalState.NOT_APPROVED) {
      return 'UNLOCK_TOKEN'
    } else if (this.approvalState === ApprovalState.PENDING) {
      return 'UNLOCK_TOKEN_LOADING'
    } else if (
      this.isWalletConnected && this.isCorrectNetwork &&
      (
        !this.tokenAInput?.amount ||
        bignumber(this.tokenAInput?.amount).isZero()
      )
    ) {
      return 'ENTER_TOKEN_AMOUNT'
    } else if (
      (bignumber(this.tokenAInput?.amount).gt(this.tokensBalance[this.tokenAInput?.address]) ||
      (bignumber(this.tokenAInput?.amount).isZero() && bignumber(this.tokensBalance[this.tokenAInput?.address]).isZero()) ||
      bignumber(this.tokenAInput?.amount).isNegative()) &&
      [BestRateQueryState.UNKNOWN, BestRateQueryState.SUCCERSS].includes(this.bestRateQueryState)
    ) {
      return 'BALANCE_IS_NOT_ENOUGH'
    } else if ((!this.tokenAInput.address || !this.tokenBInput.address) && this.isCorrectNetwork) {
      return 'SELECT_A_TOKEN'
    } else if (this.bestRateQueryState === BestRateQueryState.PENDING) {
      return 'BEST_RATE_QUERY_LOADING'
    } else if (
      this.tokenAInput?.address && this.tokenAInput?.amount &&
      this.tokenBInput?.address && this.tokenAInput?.amount &&
      this.isCorrectNetwork
    ) {
      return 'SWAP'
    } else if (!this.isCorrectNetwork) {
      return 'WRONG_NETWORK'
    } else {
      // TODO: fix
      return 'CONNECT_WALLET'
    }
  }

  @Watch('tokensBalance')
  async onTokensBalanceChange(val: any) {
    if (this.tokenAInput?.address) {
      this.displayBalanceTokenA = val[this.tokenAInput.address]
    }
    if (this.tokenBInput?.address) {
      this.displayBalanceTokenB = val[this.tokenBInput.address]
    }
  }

  get displayTotalValueOfBalanceTokenA() {
    if (this.tokenAInput?.address && this.tokenPrices[this.tokenAInput.address] && this.tokensBalance[this.tokenAInput.address]) {
      return bignumber(this.tokenPrices[this.tokenAInput.address]).multipliedBy(this.tokensBalance[this.tokenAInput.address]).toString()
    }
    return '0'
  }

  get displayTotalValueOfBalanceTokenB() {
    if (this.tokenBInput?.address && this.tokenPrices[this.tokenBInput.address] && this.tokensBalance[this.tokenBInput.address]) {
      return bignumber(this.tokenPrices[this.tokenBInput.address]).multipliedBy(this.tokensBalance[this.tokenBInput.address]).toString()
    }
    return '0'
  }

  @Watch('tokenAInput')
  async onTokenAInputChange(val: TokenInputType) {
    if (val?.address && this.tokensBalance[val.address]) {
      this.displayBalanceTokenA = this.tokensBalance[val.address]
    } else {
      this.displayBalanceTokenA = '0'
    }
  }

  @Watch('tokenBInput')
  async onTokenBInputChange(val: TokenInputType) {
    if (val?.address && this.tokensBalance[val.address]) {
      this.displayBalanceTokenB = this.tokensBalance[val.address]
    } else {
      this.displayBalanceTokenB = '0'
    }
  }

  @Watch('tradeState')
  async handleTradeStateChange(status: TradeState) {
    switch (status) {
      case TradeState.REJECTED:
        this.WaitingConfirmSwapModal.closeModal()
        break
      case TradeState.SUCCERSS:
        this.clearDataAfterTradeSuccess()
        this.getTokensBalance()
        break
    }
  }

  @Watch('userAddress')
  async handleAddressChange(newAddress: string | null, oldAddress: string | null) {
    if (oldAddress === null && newAddress) {
      return
    }
    this.clearDataWhenSystemError()
    this.displayBalanceTokenA = '0'
    this.displayBalanceTokenB = '0'
    this.handleTokenInput('tokenA', DEFAULT_TOKEN_A_INPUT_ADDRESS)
    const defaultTokenAData = this.allToken.find((token: Token) => token.address === DEFAULT_TOKEN_A_INPUT_ADDRESS) as Token
    this.getTokensBalance([defaultTokenAData])
  }

  @Watch('bestRateQuery')
  async handleBestRateQueryChange() {
    this.handleDefaultToken()
  }
}
