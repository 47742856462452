import { getProviderInfo } from 'web3modal'
import { WalletProvider, WalletInfo, WalletConnector } from '../types'
import WalletConnectProvider from '@walletconnect/web3-provider'
import { Network } from '../types/Web3'
import {
  InjectedWeb3Connector,
  WalletConnectConnector,
  WalletLinkConnector,
  FortmaticConnector,
  BinanceChainWalletConnector
} from '../connectors'

const APP_NAME = process.env.VUE_APP_APP_NAME || 'WARDEN'
const APP_LOGO_URL = process.env.VUE_APP_APP_LOGO_URL || 'https://wardenswap.finance/static/favicon.ico'
const ETH_JSONRPC_URL = process.env.VUE_APP_ETH_NODE_URL || 'PLEASE_PROVIDE_ENV_ETH_NODE_URL'
const CHAIN_ID = process.env.VUE_APP_CHAIN_ID !== undefined ? parseInt(process.env.VUE_APP_CHAIN_ID) : 1
const FORTMATIC_KEY = process.env.VUE_APP_FORTMATIC_KEY || 'PLEASE_PROVIDE_ENV_FORTMATIC_KEY'

export function walletLogo(walletProvider: WalletProvider, walletName: string) {
  switch (walletProvider) {
    case WalletProvider.INJECTED_WEB3:
      return walletName === 'MetaMask' ? '/static/svg/wallet-metamask.svg' : '/static/svg/wallet-web3.svg'
    case WalletProvider.WALLET_CONNECT:
      return '/static/svg/wallet-connect.svg'
    case WalletProvider.FORTMATIC:
      return '/static/svg/wallet-fortmatic.svg'
    case WalletProvider.WALLET_LINK:
      return '/static/svg/wallet-coinbase.svg'
    case WalletProvider.BINANCE_CHAIN:
      return '/static/svg/binance-wallet.svg'
  }
}

export function getWalletInfo(provider: WalletConnectProvider, walletProvider: WalletProvider): WalletInfo {
  const info = getProviderInfo(provider)
  const metaName = provider.walletMeta && provider.walletMeta.name
  const walletName = metaName ?? info.name

  console.log(info)
  return {
    // @ts-ignore
    provider: walletProvider,
    name: walletName,
    type: info.type,
    logo: walletLogo(walletProvider, walletName)
  }
}

export function getNetwork(id: number): Network {
  switch (id) {
    case 1: return Network.main
    case 3: return Network.ropsten
    case 4: return Network.rinkeby
    case 5: return Network.goerli
    case 42: return Network.kovan
    case 56: return Network.bsc
    case 97: return Network.bsctestnet
    default: return Network.private
  }
}

export async function getConnector(walletProvider: WalletProvider) {
  let connector: WalletConnector
  switch (walletProvider) {
    case WalletProvider.INJECTED_WEB3:
      connector = new InjectedWeb3Connector()
      break
    case WalletProvider.WALLET_CONNECT:
      connector = new WalletConnectConnector(ETH_JSONRPC_URL)
      break
    case WalletProvider.FORTMATIC:
      connector = new FortmaticConnector(
        FORTMATIC_KEY,
        ETH_JSONRPC_URL,
        CHAIN_ID
      )
      break
    case WalletProvider.WALLET_LINK:
      connector = new WalletLinkConnector(
        APP_NAME,
        APP_LOGO_URL,
        ETH_JSONRPC_URL,
        CHAIN_ID
      )
      break
    case WalletProvider.BINANCE_CHAIN:
      connector = new BinanceChainWalletConnector()
      break
  }
  return connector
}
