


























import { Component, Vue } from 'vue-property-decorator'

// Logic for random slide show
// :activeIndex="Math.floor(Math.random() * images.length)"

@Component({
  name: 'BannerCarousel'
})
export default class SlippageSelector extends Vue {
  prevLabel = `<img src='${require('@/assets/svg/icon-left-arrow.svg')}'/>`
  nextLabel = `<img src='${require('@/assets/svg/icon-right-arrow.svg')}'/>`

  images = [
    {
      itemImageSrc: require('@/assets/svg/banner_best_rate_search_engine.svg'),
      link: 'https://bit.ly/3tIr1tA'
    },
    {
      itemImageSrc: require('@/assets/svg/banner_dodo.svg'),
      link: 'https://bit.ly/3v4LQjA'
    },
    {
      itemImageSrc: require('@/assets/svg/banner_twindex.svg'),
      link: 'https://bit.ly/3fhjQ7D'
    },
    {
      itemImageSrc: require('@/assets/svg/banner_audit_certik.svg'),
      link: 'https://bit.ly/3eygNba'
    },
    {
      itemImageSrc: require('@/assets/svg/banner_dopple.svg'),
      link: 'https://bit.ly/3tqzAcx'
    },
    {
      itemImageSrc: require('@/assets/svg/banner_ape.svg'),
      link: 'https://bit.ly/3xyWZLY'
    },
    {
      itemImageSrc: require('@/assets/svg/banner_pancake_v2.svg'),
      link: 'https://bit.ly/3axWkRf'
    },
    {
      itemImageSrc: require('@/assets/svg/banner_belt.svg'),
      link: 'https://bit.ly/2QEq6wF'
    },
    {
      itemImageSrc: require('@/assets/svg/banner_mdex.svg'),
      link: 'https://bit.ly/3toKHnf'
    },
    {
      itemImageSrc: require('@/assets/svg/banner_definix.svg'),
      link: 'https://bit.ly/2QkHy8Z'
    },
    {
      itemImageSrc: require('@/assets/svg/banner_nerve.svg'),
      link: 'https://bit.ly/39Ln68a'
    },
    {
      itemImageSrc: require('@/assets/svg/banner_acryptos.svg'),
      link: 'https://bit.ly/3sIFWVa'
    },
    {
      itemImageSrc: require('@/assets/svg/banner_spartan.svg'),
      link: 'https://bit.ly/39iTz5F'
    },
    {
      itemImageSrc: require('@/assets/svg/banner_ellipsis.svg'),
      link: 'https://bit.ly/3rmg2op'
    }
  ]

  openLinkNewTab(uri: string) {
    window.open(uri, '_blank')
  }

  isMobile() {
    return window.innerWidth <= 768
  }
}
