

























































import { Vue, Component, Prop, Emit } from 'vue-property-decorator'
import { getTokenImage, handleTokenImageError } from '@/utils/helper'
import { BSC_SCAN_URL } from '@/constants'
import { Token } from '@/types'

@Component({
  name: 'AcceptCustomToken'
})
export default class AcceptCustomToken extends Vue {
  @Prop() readonly token!: Token

  getTokenImage = getTokenImage
  handleTokenImageError = handleTokenImageError
  checked = false

  openLinkBSCNewTab() {
    const uri = `${BSC_SCAN_URL}/token/${this.token.address}`
    window.open(uri, '_blank')
  }

  @Emit()
  handleContinueBtn() {
    return this.token
  }
}
