













import { Component, Prop, Vue, Emit } from 'vue-property-decorator'

@Component({
  name: 'PercentageSelector'
})
export default class SlippageSelector extends Vue {
  @Prop({ default: [] }) readonly percentageList!: Array<number>
  @Prop() readonly defaultPercentage!: string | number
  percentageSelected: number | string = 0

  created() {
    if (this.defaultPercentage) {
      this.percentageSelected = this.defaultPercentage
    }
  }

  @Emit()
  selectdPercentage(value: number) {
    this.percentageSelected = value
    return value
  }

  resetEventSelect() {
    this.percentageSelected = 0
  }
}
