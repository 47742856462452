
























import { Vue, Component } from 'vue-property-decorator'

@Component({
  name: 'ContactInfo'
})
export default class SlippageSelector extends Vue {
}
