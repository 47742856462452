






















import { Component } from 'vue-property-decorator'
import { AbstractSwapView } from '@/features/Swap/abstractView'
import { getTotalVolume } from '@/resources/thirdPartyApi'
import { logToRollbar } from '@/utils/helper'
import NumberAnimation from '@/components/NumberAnimation.vue'

@Component({
  name: 'TotalTradingVolumebox',
  components: {
    NumberAnimation
  }
})
export default class TotalTradingVolumebox extends AbstractSwapView {
  totalVolumeAllTime = ''

  created() {
    this.getTotalVolumeFromApi()
    setInterval(() => this.getTotalVolumeFromApi(), 1000 * 10) // 10s
  }

  async getTotalVolumeFromApi() {
    try {
      const { data } = await getTotalVolume()
      this.totalVolumeAllTime = data.totalVolumeAllTime
    } catch (error) {
      logToRollbar('error', 'Error: Function getTotalVolumeFromApi', error)
    }
  }
}
