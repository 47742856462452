export enum WalletProvider {
  METAMASK = 'MetaMask',
  BINANCE_CHAIN_WALLET = 'BinanceChainWallet',
  WALLET_CONNCET= 'WalletConnect',
  TRUST_WALLET = 'TrustWallet',
  TOKEN_POCKET = 'TokenPocket',
  MATH_WALLET = 'MathWallet'
}
export enum DisplayWalletProvider {
  INJECTED_WEB3 = 'MetaMask',
  WALLET_CONNECT = 'Wallet Connect',
  FORTMATIC = 'Formatic',
  WALLET_LINK = 'WALLET_LINK',
  BINANCE_CHAIN = 'Binance Chain Wallet'
}
export type BestRateButtonStatus =
  'CONNECT_WALLET' |
  'WRONG_NETWORK' |
  'UNLOCK_TOKEN' |
  'UNLOCK_TOKEN_LOADING' |
  'ENTER_TOKEN_AMOUNT' |
  'BALANCE_IS_NOT_ENOUGH' |
  'SELECT_A_TOKEN' |
  'SWAP' |
  'BEST_RATE_QUERY_LOADING' |
  'UNVALUABLE' |
  'FAIL'

export interface Token {
  name: string;
  symbol: string;
  address: string;
  chainId?: number;
  decimals: number;
  logoURI?: string | undefined;
}

export interface TokenInput {
  address?: string;
  symbol?: string;
  amount?: string;
}
export interface CurrentBestRate {
  routeIndex: string;
  routeName: string;
  amountIn: string;
  amountOut: string;
  percentage: string;
}

export enum ApprovalState {
  UNKNOWN,
  NOT_APPROVED,
  PENDING,
  APPROVED
}

export enum BestRateQueryState {
  UNKNOWN,
  PENDING,
  SUCCERSS,
  FAIL,
  UNVALUABLE
}

export enum BestRateQuerySide {
  UNKNOWN,
  FROM_A_TO_B,
  FROM_B_TO_A,
}

export enum TradeState {
  UNKNOWN,
  PENDING,
  REJECTED,
  WAIT_TX_CONFIRM,
  SUCCERSS,
  FAIL,
  ERROR
}

export enum QuoteToken {
  'BNB' = 'BNB',
  'CAKE' = 'CAKE',
  'SYRUP' = 'SYRUP',
  'BUSD' = 'BUSD',
  'TWT' = 'TWT',
  'UST' = 'UST',
  'ETH' = 'ETH',
  'COMP' = 'COMP',
  'SUSHI' = 'SUSHI',
  'TPT' = 'TPT',
}

export interface FarmConfig {
  pid: number;
  lpSymbol: string;
  lpAddresses: string;
}

export interface UserLocalStorage {
  tokens: {
    [address: string]: Token;
  };
  createdTimestamp: number;
  lastUpdateTimestamp?: number;
}

export interface ErrorHTMLImageElement {
  target: HTMLImageElement;
}
