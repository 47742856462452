import Web3 from 'web3'
import Fortmatic from 'fortmatic'
import { getWalletInfo } from '../utils'
import { WalletProvider, WalletConnector } from '../types'

export class FortmaticConnector implements WalletConnector {
  private readonly apiKey: string
  private readonly jsonRpcUrl: string
  private readonly chainId: number

  public web3: Web3 | null = null
  public provider: any = null
  public fortmatic: any

  constructor(
    apiKey: string,
    jsonRpcUrl: string,
    chainId: number
  ) {
    this.apiKey = apiKey
    this.jsonRpcUrl = jsonRpcUrl
    this.chainId = chainId
  }

  async connect() {
    const networkOption = {
      rpcUrl: this.jsonRpcUrl,
      chainId: this.chainId
    }

    const fm = new Fortmatic(this.apiKey, networkOption)
    await fm.user.login()

    this.provider = fm.getProvider()
    this.web3 = new Web3(this.provider)
    this.fortmatic = fm
    const accounts = await this.web3.eth.getAccounts()
    return {
      provider: this.provider,
      web3: this.web3,
      accounts,
      walletInfo: getWalletInfo(this.provider, WalletProvider.FORTMATIC)
    }
  }

  async disconnect() {
    await this.fortmatic.user.logout()
    this.fortmatic = null
    this.provider = null
    this.web3 = null
  }
}
