// constant for web page info
export const APP_NAME = 'WARDEN'
export const WARDEN_TOKEN_IMAGE = 'https://image.wardenswap.finance/WAD-Token.png'
export const WARDEN_TITLE_WAB_PAGE = 'WARDEN SWAP Best Price Swap on Binance Smart Chain'
export const BSC_SCAN_URL = 'https://bscscan.com'
export const TRUST_WALLET_GITHUB_ASSET_URL = 'https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/smartchain/assets'
export const BSC_DATASEED_URL = 'https://bsc-dataseed2.binance.org'
export const WARDEN_API_URL = 'https://api.wardenswap.finance/v1/api'
export const WARDEN_EXCHANGE_STAT_S3_URL = 'https://w-exchange-stat.s3-ap-southeast-1.amazonaws.com'
export const DEFAULT_GAS_PRICE = '5000000000' // 5 Gwei
export const DEFAULT_GAS_LIMIT_FOR_READ_METHOD = '400000000'
export const DEFAULT_TOKEN_A_INPUT_ADDRESS = '0xeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee'
export const DEFAULT_PRICE_SLIPPAGE = '1.5' // 1.5%
export const MAX_PRICE_IMPACT = '5' // 5%
export const USD_AMOUNT_FOR_CALC_TOKEN_PRICE = 100 // $100
export const MIN_BNB_SWAP = 0.01 // 0.01 BNB

// constant for smartcontract
export const WARDEN_CONTRACT_ADDRESS = '0xB75Fa2A799FC7935f37500Ba9780CBE10aA6610A'
export const WARDEN_BEST_RATE_QUERY_CONTRACT_ADDRESS = '0x8D32aA3cE12c9869156eBa5e384Ea00E81c34EFf'
export const WARDEN_MASTER_CHEF_ADDRESS = '0xde866dD77b6DF6772e320dC92BFF0eDDC626C674'
export const WARDEN_ALL_ROUTE = [
  0, 1, 2, 3, 4, 5, 10, 7, 11, 9, 12, 13, 14, 16, 17, 18, 19, 20, 21, 22, 23, 24,
  42, 43, 44, 45, 46, 47, 48, 49, 50,
  59, 60, 61, 62, 63, 64, 65, 66,
  68, 69, 76, 77, 78, 79,
  80, 81, 82, 83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99,
  100, 101, 102, 103, 104, 105, 106, 107, 110, 111, 112, 113, 114,
  115, 116, 117, 118, 119, 120, 121, 122, 123, 124, 125, 126,
  127, 128, 129, 130, 131, 132, 133, 134, 135, 136, 137, 138, 139, 140, 141, 142, 143, 144, 145, 146, 147, 148, 149, 150,
  151, 152, 153, 154, 155, 156, 157, 158, 159, 160, 161, 162, 163,
  164, 165, 166, 167, 168, 170, 171, 172, 173, 175, 176, 177, 178, 179, 180, 181, 182, 183, 184, 185, 186, 187, 188, 189,
  190, 191, 192, 193, 194, 195, 196, 197, 198, 199, 200, 201, 202, 203, 204, 205, 206, 207, 208, 209,
  213,
  214, 215, 216, 217, 218, 219, 220, 221, 222, 223, 224, 225, 226, 227, 228,
  229, 230, 231, 232, 233, 234, 235, 236, 237, 238, 239, 240, 241, 242, 243
]

export const WARDEN_ALL_ROUTE_FOR_SPLIT_TRADE = [0, 1, 4, 5, 10, 7, 11, 9, 12, 13, 14, 16, 17, 18, 19, 20, 24]
export const WARDEN_ALL_ROUTE_FOR_SPLIT_TRADE_V2 = [0, 2, 3, 4, 10, 7, 11, 9, 12, 13, 14, 16, 17, 18, 19, 20, 24]
export const SIX_FINIX_ROUTES = [31, 26, 27, 28, 29, 30, 70, 71]
export const WARDEN_PARTNER_INDEX = 0
export const WARDEN_PERCENT_STEP = 10 // 10%
