import { Module, VuexModule, Mutation, MutationAction, getModule } from 'vuex-module-decorators'
import { stateDecoratorFactory } from 'vuex-module-decorators-state'
import Bignumber from '@/utils/bignumber'
import tokenList from '@/constants/token/wardenswap.json'
import Store from '@/store'
import {
  getTokenComparator,
  precisionNumber,
  numberWithCommas
} from '@/utils/helper'
import {
  Token,
  TokenInput,
  CurrentBestRate,
  BestRateQueryState,
  ApprovalState,
  TradeState,
  BestRateQuerySide
} from '@/types'
@Module({
  namespaced: true,
  dynamic: true,
  store: Store,
  name: 'swap'
})
export class Swap extends VuexModule {
  tokensBalance: { [key: string]: any } = {}
  allToken: Token[] = [...tokenList]
  // TODO: fix type
  tokenAInput: any = {}
  tokenBInput: any = {}
  currentBestRate: CurrentBestRate[] = []
  tokenPrices: { [key: string]: string } = {}
  priceSlippage = '0'
  bestRateQueryState: BestRateQueryState = BestRateQueryState.UNKNOWN
  approvalState: ApprovalState = ApprovalState.UNKNOWN
  tradeState: TradeState = TradeState.UNKNOWN
  bestRateQuerySide: BestRateQuerySide = BestRateQuerySide.UNKNOWN
  lastBestRateQuerySide: BestRateQuerySide = BestRateQuerySide.UNKNOWN
  getBestRateCount = 0
  isAllowancedCount = 0
  showInvertTradePrice = false
  wadTokenPriceUsd = '0'
  priceImpact = '0.00'

  get computeTradePrice(): string | null {
    if (!this.currentBestRate?.length) {
      return null
    }
    // TODO: refactoring code
    const groupTokenAmount = this.currentBestRate.reduce((acc: any, cur: any) => {
      acc.amountIn = acc.amountIn.plus(cur.amountIn)
      acc.amountOut = acc.amountOut.plus(cur.amountOut)
      return acc
    }, { amountIn: Bignumber('0'), amountOut: Bignumber('0') })

    let showTradePrice = ''
    if (this.showInvertTradePrice) {
      const tradePrice = Bignumber(groupTokenAmount.amountOut).div(groupTokenAmount.amountIn).toString()
      showTradePrice = `${numberWithCommas(precisionNumber(tradePrice).toString())} ${this.tokenBInput.symbol} per ${this.tokenAInput.symbol}`
    } else {
      const tradePrice = Bignumber(groupTokenAmount.amountIn).div(groupTokenAmount.amountOut).toString()
      showTradePrice = `${numberWithCommas(precisionNumber(tradePrice).toString())} ${this.tokenAInput.symbol} per ${this.tokenBInput.symbol}`
    }
    return showTradePrice
  }

  // TODO: refactor code
  @Mutation
  setTokensBalance(val: { [key: string]: any }) {
    // this.tokensBalance[val.address] = val.value
    this.tokensBalance = {
      ...this.tokensBalance,
      [val.address]: val.value
    }
    this.allToken
      .sort((tokenA: Token, tokenB: Token): number => {
        return tokenA.symbol.toLowerCase() < tokenB.symbol.toLowerCase() ? -1 : 1
      })
      .sort((tokenA: Token, tokenB: Token): number => {
        return getTokenComparator(this.tokensBalance, tokenA, tokenB)
      })
  }

  @Mutation
  clearTokensBalance() {
    this.tokensBalance = {}
  }

  @Mutation
  setTokenAInput(tokenInput: TokenInput | {}) {
    this.tokenAInput = tokenInput
  }

  @Mutation
  setTokenBInput(tokenInput: TokenInput | {}) {
    this.tokenBInput = tokenInput
  }

  @Mutation
  setTokenAInputAmount(tokenInputAmount: string) {
    this.tokenAInput = { ...this.tokenAInput, amount: tokenInputAmount }
  }

  @Mutation
  setTokenBInputAmount(tokenInputAmount: string) {
    this.tokenBInput = { ...this.tokenBInput, amount: tokenInputAmount }
  }

  @Mutation
  setCurrentBestRate(currentBestRate: CurrentBestRate[]) {
    this.currentBestRate = currentBestRate
  }

  @MutationAction({ mutate: ['allToken'] })
  async setAllToken(allToken: Token[]) {
    return { allToken }
  }

  @MutationAction({ mutate: ['approvalState'] })
  async setApprovalState(approvalState: ApprovalState) {
    return { approvalState }
  }

  @MutationAction({ mutate: ['priceSlippage'] })
  async setPriceSlippage(priceSlippage: string) {
    return { priceSlippage }
  }

  @MutationAction({ mutate: ['bestRateQueryState'] })
  async setBestRateQueryState(bestRateQueryState: BestRateQueryState) {
    return { bestRateQueryState }
  }

  @MutationAction({ mutate: ['tradeState'] })
  async setTradeState(tradeState: TradeState) {
    return { tradeState }
  }

  @MutationAction({ mutate: ['tradeState'] })
  async setTradeTxhash(tradeState: TradeState) {
    return { tradeState }
  }

  @MutationAction({ mutate: ['bestRateQuerySide'] })
  async setbestRateQuerySide(bestRateQuerySide: BestRateQuerySide) {
    return { bestRateQuerySide }
  }

  @MutationAction({ mutate: ['lastBestRateQuerySide'] })
  async setLastBestRateQuerySide(lastBestRateQuerySide: BestRateQuerySide) {
    return { lastBestRateQuerySide }
  }

  @Mutation
  increaseGetBestRateCount() {
    this.getBestRateCount++
  }

  @Mutation
  increaseIsAllowancedCount() {
    this.isAllowancedCount++
  }

  @MutationAction({ mutate: ['showInvertTradePrice'] })
  async setShowInvertTradePrice(showInvertTradePrice: boolean) {
    return { showInvertTradePrice }
  }

  @MutationAction({ mutate: ['wadTokenPriceUsd'] })
  async setWadTokenPriceUsd(wadTokenPriceUsd: string) {
    return { wadTokenPriceUsd }
  }

  @Mutation
  addTokenPrice(tokenPrice: { [key: string]: string }) {
    this.tokenPrices = { ...this.tokenPrices, ...tokenPrice }
  }

  @MutationAction({ mutate: ['priceImpact'] })
  async setPriceImpact(priceImpact: string) {
    return { priceImpact }
  }
}

export const SwapModule = getModule(Swap)
export const SwapState = stateDecoratorFactory(SwapModule)
