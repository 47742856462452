import Web3 from 'web3'
import WalletLink from 'walletlink'
import { WalletProvider, WalletInfo, WalletConnector } from '../types'
import { walletLogo } from '../utils'
export class WalletLinkConnector implements WalletConnector {
  private readonly appName: string
  private readonly appLogoUrl: string
  private readonly jsonRpcUrl: string
  private readonly chainId: number

  public web3: Web3 | null = null
  public provider: any = null

  constructor(
    appName: string,
    appLogoUrl: string,
    jsonRpcUrl: string,
    chainId: number
  ) {
    this.appName = appName
    this.appLogoUrl = appLogoUrl
    this.jsonRpcUrl = jsonRpcUrl
    this.chainId = chainId
  }

  getWalletLink() {
    // Initialize WalletLink
    const walletLink = new WalletLink({
      appName: this.appName,
      appLogoUrl: this.appLogoUrl,
      darkMode: false
    })
    return walletLink
  }

  async connect() {
    const walletLink = this.getWalletLink()
    // Initialize a Web3 Provider object
    const ethereum = walletLink.makeWeb3Provider(this.jsonRpcUrl, this.chainId)
    // Alternatively, you can use ethereum.enable()
    const accounts = await ethereum.enable()

    console.log(`User's address is ${accounts[0]}`)

    // Initialize a Web3 object
    this.provider = ethereum
    this.web3 = new Web3(this.provider)

    const provider = WalletProvider.WALLET_LINK
    const name = 'WalletLink'
    const walletInfo: WalletInfo = {
      provider: provider,
      name: name,
      type: name,
      logo: walletLogo(provider, name)
    }

    return {
      provider: this.provider,
      web3: this.web3,
      accounts,
      walletInfo
    }
  }

  async disconnect() {
    this.provider!.close()
    this.provider = null
    this.web3 = null
  }
}
