import { Component } from 'vue-property-decorator'
import { AbstractWeb3ConnectorView } from '../../Web3Connector/abstractView'
import { SwapState, SwapModule } from '../store'
import { Web3ConnectorState } from '@/features/Web3Connector/store'
import ERC20_ABI from '@/constants/abis/erc20.json'
import MASTERCHEF_ABI from '@/constants/abis/masterchef.json'
import Bignumber from '@/utils/bignumber'
import { ethers, Contract, utils, constants } from 'ethers'
import { Contract as ContractMulticall, Provider as ProviderMulticall } from 'ethers-multicall'
import { Token, TokenInput, CurrentBestRate, ApprovalState, BestRateQueryState, TradeState, BestRateQuerySide } from '@/types'
import { getAddress } from '@ethersproject/address'
import { TransactionResponse, TransactionReceipt } from '@ethersproject/providers'
import {
  BSC_DATASEED_URL,
  WARDEN_CONTRACT_ADDRESS,
  WARDEN_ALL_ROUTE,
  WARDEN_ALL_ROUTE_FOR_SPLIT_TRADE,
  WARDEN_ALL_ROUTE_FOR_SPLIT_TRADE_V2,
  SIX_FINIX_ROUTES,
  WARDEN_PARTNER_INDEX,
  WARDEN_PERCENT_STEP,
  WARDEN_MASTER_CHEF_ADDRESS,
  WARDEN_TOKEN_IMAGE,
  DEFAULT_GAS_LIMIT_FOR_READ_METHOD,
  MAX_PRICE_IMPACT,
  USD_AMOUNT_FOR_CALC_TOKEN_PRICE
} from '@/constants'
import {
  calculateGasMargin,
  isAddress,
  logToRollbar,
  loadUserStorage
} from '@/utils/helper'
import farmsConfig from '@/constants/farms'
import { groupTokenAmountFromBestRateData } from '../utils'
@Component
export class AbstractSwapView extends AbstractWeb3ConnectorView {
  @SwapState public readonly allToken!: Token[]
  @SwapState public readonly tokensBalance!: { [key: string]: any }
  // TODO: fix type
  @SwapState public readonly tokenAInput!: any
  @SwapState public readonly tokenBInput!: any
  @SwapState public readonly currentBestRate!: any
  @SwapState public readonly tokenPrices!: { [key: string]: string }
  @SwapState public readonly priceSlippage!: string
  @SwapState public readonly bestRateQueryState!: BestRateQueryState
  @SwapState public readonly bestRateQuerySide!: BestRateQuerySide
  @SwapState public readonly lastBestRateQuerySide!: BestRateQuerySide
  @SwapState public readonly approvalState!: ApprovalState
  @SwapState public readonly tradeState!: TradeState
  @SwapState public readonly computeTradePrice!: string | null
  @SwapState public readonly getBestRateCount!: number
  @SwapState public readonly isAllowancedCount!: number
  @SwapState public readonly showInvertTradePrice!: boolean
  @SwapState public readonly wadTokenPriceUsd!: string
  @SwapState public readonly priceImpact!: string

  @Web3ConnectorState public readonly wardenSwap!: Contract
  @Web3ConnectorState public readonly bestRateQuery!: Contract
  @Web3ConnectorState public readonly provider: any | null
  @Web3ConnectorState public readonly gasPrice!: string

  tokenInputDelayTimer: undefined | ReturnType<typeof setTimeout> = setTimeout(() => '', 100)
  isFindBestRateTwoRouteFail = false
  isFindBestRateTwoRouteV2Fail = false

  async getTokenContract(tokenAddress: string) {
    let etherProvider = null
    try {
      etherProvider = new ethers.providers.Web3Provider(this.provider)
    } catch (error) {
      etherProvider = new ethers.providers.JsonRpcProvider(BSC_DATASEED_URL)
    }
    const tokenContract = new ethers.Contract(tokenAddress, ERC20_ABI, etherProvider)
    return tokenContract
  }

  public handleTokenToVuexState(tokenSelected: Token) {
    if (this.allToken.some((token: Token) => token?.address === tokenSelected.address)) {
      return
    }
    this.increaseTokenInState(tokenSelected)
  }

  increaseTokenInState(tokenData: Token) {
    const tempOfAllToken: Token[] = [...this.allToken]
    tempOfAllToken.push(tokenData)
    SwapModule.setAllToken(tempOfAllToken)
  }

  decreaseTokenInState(tokenAddress: string) {
    let tempOfAllToken: Token[] = [...this.allToken]
    tempOfAllToken = tempOfAllToken.filter((token: Token) => token.address !== tokenAddress)
    SwapModule.setAllToken(tempOfAllToken)
    this.clearTokenInputWhenDecreaseTokenInState(tokenAddress)
  }

  handleUpdateAllToken() {
    const currentUserStorage = loadUserStorage()
    if (!currentUserStorage.hasOwnProperty('tokens')) {
      return
    }
    const tokenFromUserStorage: Token[] = []
    Object.values(currentUserStorage.tokens).forEach(token => {
      if (!this.allToken.some(t => getAddress(t.address) === getAddress(token.address))) {
        tokenFromUserStorage.push(token)
      }
    })
    const allTokenIncludeTokenFromUserStorage: Token[] = [...this.allToken, ...tokenFromUserStorage]
    SwapModule.setAllToken(allTokenIncludeTokenFromUserStorage)
  }

  async getTokenInfo(tokenAddress: string): Promise<Token | Error> {
    if (!isAddress(tokenAddress)) {
      return new Error(`${tokenAddress} is not token contract address`)
    }
    const tokenContract = await this.getTokenContract(tokenAddress)
    const decimals = await tokenContract.decimals()
    const symbol = await tokenContract.symbol()
    const name = await tokenContract.name()
    return { address: tokenAddress, decimals, symbol, name }
  }

  public async callMethodEligibleForFreeTrade(): Promise<boolean | void> {
    if (!this.userAddress) {
      return
    }
    const result = await this.wardenSwap.isEligibleForFreeTrade(this.userAddress)
    return result
  }

  public async getEligibleAmount(): Promise<string | void> {
    if (!this.userAddress) {
      return
    }
    const eligibleAmount = await this.wardenSwap.eligibleAmount()
    const eligibleAmountInBase = utils.formatUnits(eligibleAmount, 18)
    return eligibleAmountInBase
  }

  public async clearDataAfterTradeSuccess() {
    if (this.tradeState !== TradeState.SUCCERSS) {
      return
    }
    const tempTokenA = this.tokenAInput
    const tempTokenB = this.tokenBInput
    await SwapModule.setTokenAInputAmount('')
    await SwapModule.setTokenBInputAmount('')
    await SwapModule.setCurrentBestRate([])
    await SwapModule.setPriceSlippage('0')
    await SwapModule.setPriceImpact('0.00')
    await SwapModule.setBestRateQueryState(BestRateQueryState.UNKNOWN)
    await SwapModule.setbestRateQuerySide(BestRateQuerySide.UNKNOWN)
    const tokenAData = this.allToken.find((token: Token) => token.address === tempTokenA.address) as Token
    const tokenBData = this.allToken.find((token: Token) => token.address === tempTokenB.address) as Token
    this.getTokensBalance([tokenAData, tokenBData])
  }

  public async clearDataWhenSystemError() {
    await SwapModule.setTokenAInput({})
    await SwapModule.setTokenBInput({})
    await SwapModule.setCurrentBestRate([])
    await SwapModule.setPriceSlippage('0')
    await SwapModule.setPriceImpact('0.00')
    await SwapModule.clearTokensBalance()
    SwapModule.setBestRateQueryState(BestRateQueryState.UNKNOWN)
    SwapModule.setbestRateQuerySide(BestRateQuerySide.UNKNOWN)
    SwapModule.setApprovalState(ApprovalState.UNKNOWN)
    SwapModule.setTradeState(TradeState.UNKNOWN)
  }

  private clearTokenInputWhenDecreaseTokenInState(tokenAddress: string) {
    if (this.tokenAInput.address === tokenAddress) {
      SwapModule.setTokenAInput({})
    }
    if (this.tokenBInput.address === tokenAddress) {
      SwapModule.setTokenBInput({})
    }
  }

  public async getTokensBalance(tookenList: Token[] = this.allToken): Promise<any> {
    if (!this.userAddress || !this.isCorrectNetwork) {
      return
    }
    await Promise.all(tookenList.map(async(token: Token) => {
      if (token.symbol === 'BNB' && this.userAddress) {
        const etherProvider = new ethers.providers.Web3Provider(this.provider)
        const wei = await etherProvider.getBalance(this.userAddress)
        const bnbBalance = utils.formatEther(wei).toString()
        const newData = { address: token.address, value: bnbBalance }
        SwapModule.setTokensBalance(newData)
      } else {
        const tokenContract = await this.getTokenContract(token.address)
        const tokenBalance = await tokenContract.balanceOf(this.userAddress)
        const decimals = await tokenContract.decimals()
        const value = utils.formatUnits(tokenBalance, decimals)
        const newData = { address: token.address, value }
        SwapModule.setTokensBalance(newData)
      }
    }))
  }

  public setTokenInput(type: 'tokenA' | 'tokenB', tokenInput: TokenInput): void {
    SwapModule.setCurrentBestRate([])
    SwapModule.setApprovalState(ApprovalState.UNKNOWN)
    if (tokenInput?.address) {
      this.handleTokenPrice(tokenInput.address)
    }
    switch (type) {
      case 'tokenA':
        SwapModule.setTokenAInput(tokenInput)
        if (this.tokenAInput?.address === this.tokenBInput.address) {
          SwapModule.setTokenBInput({})
          return
        }
        if (!this.tokenAInput.amount || (this.tokenAInput?.amount && Bignumber(this.tokenAInput.amount).isZero())) {
          SwapModule.setTokenBInputAmount('')
          return
        }
        this.handelBestRateQuery('fromTokenAToB')
        break
      case 'tokenB':
        SwapModule.setTokenBInput(tokenInput)
        if (this.tokenBInput?.address === this.tokenAInput.address) {
          SwapModule.setTokenAInput({})
          return
        }
        this.handelBestRateQuery('fromTokenBToA')
        break
    }
  }

  public setTokenInputAmount(type: 'tokenA' | 'tokenB', tokenInputAmount: string): void {
    SwapModule.setCurrentBestRate([])
    if (this.tokenInputDelayTimer) {
      clearTimeout(this.tokenInputDelayTimer)
    }
    switch (type) {
      case 'tokenA':
        SwapModule.setTokenAInputAmount(tokenInputAmount)
        if (Bignumber(tokenInputAmount).isZero() || tokenInputAmount === '') {
          SwapModule.setTokenBInputAmount('')
          return
        }
        this.tokenInputDelayTimer = setTimeout(async() => {
          this.handelBestRateQuery('fromTokenAToB')
        }, 500)
        break
      case 'tokenB':
        SwapModule.setTokenBInputAmount(tokenInputAmount)
        if (Bignumber(tokenInputAmount).isZero() || tokenInputAmount === '') {
          SwapModule.setTokenAInputAmount('')
          return
        }
        this.tokenInputDelayTimer = setTimeout(async() => {
          this.handelBestRateQuery('fromTokenBToA')
        }, 500)
        break
    }
  }

  public async swapTokenInput() {
    const oldTokenAInput = Object.assign({}, JSON.parse(JSON.stringify(this.tokenAInput)))
    const oldTokenBInput = Object.assign({}, JSON.parse(JSON.stringify(this.tokenBInput)))
    // ---------------------------
    // TODO: use this feature when close feature find best rate token A -> token B
    delete oldTokenAInput?.amount
    delete oldTokenBInput?.amount
    // bypass if user click swap token and in this time system find best rate
    await SwapModule.increaseGetBestRateCount()
    // ---------------------------
    SwapModule.setTokenAInput(oldTokenBInput)
    SwapModule.setTokenBInput(oldTokenAInput)
    SwapModule.setCurrentBestRate([])
    SwapModule.setApprovalState(ApprovalState.UNKNOWN)
    // TODO: close feature
    // if (
    //   this.tokenAInput?.address &&
    //   this.tokenAInput?.amount &&
    //   !Bignumber(this.tokenAInput.amount).isZero() &&
    //   this.isCorrectNetwork
    // ) {
    //   await this.findBestRateTokenAToTokenB(this.tokenAInput.amount)
    // } else if (
    //   this.tokenBInput?.address &&
    //   this.tokenBInput?.amount &&
    //   !Bignumber(this.tokenBInput.amount).isZero() &&
    //   this.isCorrectNetwork
    // ) {
    //   await this.findBestRateTokenBToTokenA(this.tokenBInput.amount)
    // }
  }

  public async findBestRateTokenAToTokenB(tokenInputAmount: string) {
    SwapModule.setBestRateQueryState(BestRateQueryState.PENDING)
    SwapModule.setbestRateQuerySide(BestRateQuerySide.FROM_A_TO_B)
    SwapModule.setLastBestRateQuerySide(BestRateQuerySide.FROM_A_TO_B)
    await SwapModule.setTokenBInputAmount('')
    await SwapModule.increaseGetBestRateCount()
    SwapModule.setPriceImpact('0.00')
    const stampGetRateCount = this.getBestRateCount
    try {
      const tokenBData = this.allToken.find((token: Token) => token.address === this.tokenBInput.address) as Token
      const [bestRateOneRoute, bestRateTwoRoute, bestRateTwoRouteV2] = await this.findBestRatefromThreePattern(this.tokenAInput, this.tokenBInput, tokenInputAmount)

      let currentBestRate: CurrentBestRate[] = []
      let totalAmountOut = '0'
      const bestRateResource = this.findBestRateTokenAToTokenBFromResultManyPattern([bestRateOneRoute, bestRateTwoRoute, bestRateTwoRouteV2])
      if (!bestRateResource || Bignumber(bestRateResource.amountOut.toString()).isZero()) {
        SwapModule.setbestRateQuerySide(BestRateQuerySide.UNKNOWN)
        SwapModule.setBestRateQueryState(BestRateQueryState.UNVALUABLE)
        return
      }

      if (!bestRateResource.hasOwnProperty('volumns')) {
        const routeName = (await this.wardenSwap.tradingRoutes(bestRateResource.routeIndex)).name
        totalAmountOut = bestRateResource.amountOut.toString()

        const amountOutExcludeFee = Bignumber(totalAmountOut).div(0.999).toFixed(0)
        totalAmountOut = amountOutExcludeFee

        totalAmountOut = utils.formatUnits(totalAmountOut, tokenBData.decimals)
        currentBestRate = [{
          routeIndex: bestRateResource.routeIndex.toString(),
          routeName: routeName,
          amountIn: tokenInputAmount,
          amountOut: totalAmountOut,
          percentage: '100'
        }]
      } else {
        totalAmountOut = bestRateResource.amountOut

        const amountOutExcludeFee = Bignumber(totalAmountOut).div(0.999).toFixed(0)
        totalAmountOut = amountOutExcludeFee

        const tokenBData = this.allToken.find((token: Token) => token.address === this.tokenBInput.address) as Token
        totalAmountOut = utils.formatUnits(bestRateResource.amountOut, tokenBData.decimals)
        currentBestRate = await this.manageBestRateTwoRoute(this.tokenAInput, this.tokenBInput, tokenInputAmount, bestRateResource)
      }
      if (stampGetRateCount === this.getBestRateCount && this.tokenAInput.amount === tokenInputAmount) {
        await this.calculatePriceImpact(currentBestRate)
        await SwapModule.setTokenBInputAmount(totalAmountOut)
        await SwapModule.setCurrentBestRate(currentBestRate)
        await SwapModule.setBestRateQueryState(BestRateQueryState.SUCCERSS)
      } else {
        SwapModule.setbestRateQuerySide(BestRateQuerySide.UNKNOWN)
      }
    } catch (error) {
      logToRollbar('error', 'Error: Function findBestRateTokenAToTokenB', error)
      console.error('Query best rate from token A to token B failed', error)
      SwapModule.setBestRateQueryState(BestRateQueryState.FAIL)
    }
    SwapModule.setbestRateQuerySide(BestRateQuerySide.UNKNOWN)
  }

  public async watchBestRateTokenAToTokenB() {
    if (!this.isCorrectNetwork) {
      return
    }
    if (
      !this.tokenAInput?.address || !this.tokenAInput?.amount || this.tokenAInput?.amount === '' || Bignumber(this.tokenAInput?.amount).lte(0) ||
      !this.tokenBInput?.address || !this.tokenBInput?.amount || this.tokenBInput?.amount === '' || Bignumber(this.tokenBInput?.amount).lte(0)
    ) {
      return
    }
    await SwapModule.increaseGetBestRateCount()
    const stampGetRateCount = this.getBestRateCount
    try {
      const tokenAInputAmount = this.tokenAInput.amount
      const tokenBData = this.allToken.find((token: Token) => token.address === this.tokenBInput.address) as Token
      const [bestRateOneRoute, bestRateTwoRoute, bestRateTwoRouteV2] = await this.findBestRatefromThreePattern(this.tokenAInput, this.tokenBInput, tokenAInputAmount)

      let currentBestRate: CurrentBestRate[] = []
      let totalAmountOut = '0'
      const bestRateResource = this.findBestRateTokenAToTokenBFromResultManyPattern([bestRateOneRoute, bestRateTwoRoute, bestRateTwoRouteV2])
      if (!bestRateResource || Bignumber(bestRateResource.amountOut.toString()).isZero()) {
        return
      }

      if (!bestRateResource.hasOwnProperty('volumns')) {
        const routeName = (await this.wardenSwap.tradingRoutes(bestRateResource.routeIndex)).name
        totalAmountOut = bestRateResource.amountOut.toString()

        const amountOutExcludeFee = Bignumber(totalAmountOut).div(0.999).toFixed(0)
        totalAmountOut = amountOutExcludeFee

        totalAmountOut = utils.formatUnits(totalAmountOut, tokenBData.decimals)
        currentBestRate = [{
          routeIndex: bestRateResource.routeIndex.toString(),
          routeName: routeName,
          amountIn: tokenAInputAmount,
          amountOut: totalAmountOut,
          percentage: '100'
        }]
      } else {
        totalAmountOut = bestRateResource.amountOut

        const amountOutExcludeFee = Bignumber(totalAmountOut).div(0.999).toFixed(0)
        totalAmountOut = amountOutExcludeFee

        const tokenBData = this.allToken.find((token: Token) => token.address === this.tokenBInput.address) as Token
        totalAmountOut = utils.formatUnits(bestRateResource.amountOut, tokenBData.decimals)
        currentBestRate = await this.manageBestRateTwoRoute(this.tokenAInput, this.tokenBInput, tokenAInputAmount, bestRateResource)
      }
      if (stampGetRateCount === this.getBestRateCount && this.tokenAInput.amount === tokenAInputAmount) {
        await this.calculatePriceImpact(currentBestRate)
        await SwapModule.setTokenBInputAmount(totalAmountOut)
        await SwapModule.setCurrentBestRate(currentBestRate)
      }
    } catch (error) {
      console.error(error)
    }
  }

  public async watchBestRateTokenBToTokenA() {
    if (!this.isCorrectNetwork) {
      return
    }
    if (
      !this.tokenAInput?.address || !this.tokenAInput?.amount || this.tokenAInput?.amount === '' || Bignumber(this.tokenAInput?.amount).lte(0) ||
      !this.tokenBInput?.address || !this.tokenBInput?.amount || this.tokenBInput?.amount === '' || Bignumber(this.tokenBInput?.amount).lte(0)
    ) {
      return
    }
    await SwapModule.increaseGetBestRateCount()
    const stampGetRateCount = this.getBestRateCount
    try {
      // Token B -> A
      let currentBestRate: CurrentBestRate[] = []
      let aAmountInBase = '0'
      const [resultOneRoute, resultTwoRoute, resultTwoRouteV2] = await Promise.all([
        this.findBestRateTokenBToTokenAOneRoute(this.tokenBInput.amount),
        this.findBestRateTokenBToTokenATwoRoute(this.tokenBInput.amount, WARDEN_ALL_ROUTE_FOR_SPLIT_TRADE),
        this.findBestRateTokenBToTokenATwoRoute(this.tokenBInput.amount, WARDEN_ALL_ROUTE_FOR_SPLIT_TRADE_V2)
      ])
      const bestRateResource = this.findBestRateTokenBToTokenAFromResultManyPattern([resultOneRoute, resultTwoRoute, resultTwoRouteV2])
      if (!bestRateResource || Bignumber(bestRateResource.aAmountInBase).isZero()) {
        return
      }

      aAmountInBase = bestRateResource.aAmountInBase
      currentBestRate = bestRateResource.currentBestRate

      if (stampGetRateCount === this.getBestRateCount) {
        await this.calculatePriceImpact(currentBestRate)
        await SwapModule.setTokenAInputAmount(aAmountInBase)
        await SwapModule.setCurrentBestRate(currentBestRate)
      }
    } catch (error) {
      console.error(error)
    }
  }

  public async findBestRateTokenBToTokenA(tokenInputAmount: string) {
    SwapModule.setBestRateQueryState(BestRateQueryState.PENDING)
    SwapModule.setbestRateQuerySide(BestRateQuerySide.FROM_B_TO_A)
    SwapModule.setLastBestRateQuerySide(BestRateQuerySide.FROM_B_TO_A)
    await SwapModule.setTokenAInputAmount('')
    await SwapModule.increaseGetBestRateCount()
    SwapModule.setPriceImpact('0.00')
    const stampGetRateCount = this.getBestRateCount
    try {
      // Token B -> A
      let currentBestRate: CurrentBestRate[] = []
      let aAmountInBase = '0'
      const [resultOneRoute, resultTwoRoute, resultTwoRouteV2] = await Promise.all([
        this.findBestRateTokenBToTokenAOneRoute(tokenInputAmount),
        this.findBestRateTokenBToTokenATwoRoute(tokenInputAmount, WARDEN_ALL_ROUTE_FOR_SPLIT_TRADE),
        this.findBestRateTokenBToTokenATwoRoute(tokenInputAmount, WARDEN_ALL_ROUTE_FOR_SPLIT_TRADE_V2)
      ])
      const bestRateResource = this.findBestRateTokenBToTokenAFromResultManyPattern([resultOneRoute, resultTwoRoute, resultTwoRouteV2])
      if (!bestRateResource || Bignumber(bestRateResource.aAmountInBase).isZero()) {
        SwapModule.setbestRateQuerySide(BestRateQuerySide.UNKNOWN)
        SwapModule.setBestRateQueryState(BestRateQueryState.UNVALUABLE)
        return
      }

      aAmountInBase = bestRateResource.aAmountInBase
      currentBestRate = bestRateResource.currentBestRate

      if (stampGetRateCount === this.getBestRateCount) {
        await this.calculatePriceImpact(currentBestRate)
        await SwapModule.setTokenAInputAmount(aAmountInBase)
        await SwapModule.setCurrentBestRate(currentBestRate)
        await SwapModule.setBestRateQueryState(BestRateQueryState.SUCCERSS)
      } else {
        SwapModule.setbestRateQuerySide(BestRateQuerySide.UNKNOWN)
      }
    } catch (error) {
      logToRollbar('error', 'Error: Function findBestRateTokenBToTokenA', error)
      console.log('Query best rate from token B to token A failed', error)
      SwapModule.setBestRateQueryState(BestRateQueryState.FAIL)
    }
    SwapModule.setbestRateQuerySide(BestRateQuerySide.UNKNOWN)
  }

  public async findBestRateTokenBToTokenAOneRoute(tokenInputAmount: string) {
    let currentBestRate: CurrentBestRate[] = []
    const tokenAData = this.allToken.find((token: Token) => token.address === this.tokenAInput.address) as Token
    const tokenBData = this.allToken.find((token: Token) => token.address === this.tokenBInput.address) as Token
    const tokenInputAmountInWei = utils.parseUnits(tokenInputAmount, tokenBData.decimals).toString()

    const tokenAChecksumAddress = getAddress(this.tokenAInput.address)
    const tokenBChecksumAddress = getAddress(this.tokenBInput.address)
    // Token B -> A
    const wardenRoutesExpected = this.getWardenRoutesExpected('oneRoute', WARDEN_ALL_ROUTE)
    const convertBToA = await this.bestRateQuery.oneRoute(tokenBChecksumAddress, tokenAChecksumAddress, tokenInputAmountInWei, wardenRoutesExpected, { gasLimit: DEFAULT_GAS_LIMIT_FOR_READ_METHOD })

    // Token A -> B
    const xAmount = convertBToA.amountOut.toString()

    const convertAToB = await this.bestRateQuery.oneRoute(tokenAChecksumAddress, tokenBChecksumAddress, xAmount, [convertBToA.routeIndex], { gasLimit: DEFAULT_GAS_LIMIT_FOR_READ_METHOD })
    const routeName = (await this.wardenSwap.tradingRoutes(convertAToB.routeIndex)).name
    const yAmount = convertAToB.amountOut.toString()

    const diffPercentage = Bignumber(tokenInputAmountInWei).minus(yAmount).multipliedBy(100).dividedBy(yAmount)
    const aAmount = Bignumber(xAmount).multipliedBy(diffPercentage.dividedBy(100).plus(1)).decimalPlaces(0).toFixed(0)

    // Token A -> B from estimate A amount
    const convertAToBRound2 = await this.bestRateQuery.oneRoute(tokenAChecksumAddress, tokenBChecksumAddress, aAmount, [convertAToB.routeIndex], { gasLimit: DEFAULT_GAS_LIMIT_FOR_READ_METHOD })
    const bAmountOutExcludeFee = Bignumber(convertAToBRound2.amountOut.toString()).div(0.999).toFixed(0)

    const aAmountInBase = utils.formatUnits(aAmount, tokenAData.decimals)
    const bAmountOutBase = utils.formatUnits(bAmountOutExcludeFee, tokenBData.decimals)

    currentBestRate = [{
      routeIndex: convertAToB.routeIndex.toString(),
      routeName: routeName,
      amountIn: aAmountInBase,
      amountOut: bAmountOutBase,
      percentage: '100'
    }]
    return { aAmountInBase, currentBestRate }
  }

  public async findBestRateTokenBToTokenATwoRoute(tokenInputAmount: string, routes: Array<number> = WARDEN_ALL_ROUTE_FOR_SPLIT_TRADE) {
    let currentBestRate: CurrentBestRate[] = []
    const tokenAData = this.allToken.find((token: Token) => token.address === this.tokenAInput.address) as Token
    const tokenBData = this.allToken.find((token: Token) => token.address === this.tokenBInput.address) as Token

    // Token B -> A
    const wardenRoutesExpected = this.getWardenRoutesExpected('splitTwoRoutes', routes)
    // @ts-ignore
    const tokenInputAmountInWei = utils.parseUnits(tokenInputAmount, tokenBData.decimals).toString()
    const tokenAChecksumAddress = getAddress(this.tokenAInput.address)
    const tokenBChecksumAddress = getAddress(this.tokenBInput.address)
    const convertBToA = await this.bestRateQuery.splitTwoRoutes(tokenBChecksumAddress, tokenAChecksumAddress, tokenInputAmountInWei, wardenRoutesExpected, WARDEN_PERCENT_STEP, { gasLimit: DEFAULT_GAS_LIMIT_FOR_READ_METHOD })
    const xAmount = convertBToA.amountOut.toString()

    // Token A -> B
    const convertAToB = await this.bestRateQuery.splitTwoRoutes(tokenAChecksumAddress, tokenBChecksumAddress, xAmount, convertBToA.routeIndexs, WARDEN_PERCENT_STEP, { gasLimit: DEFAULT_GAS_LIMIT_FOR_READ_METHOD })
    const yAmount = convertAToB.amountOut.toString()
    const diffPercentage = Bignumber(tokenInputAmountInWei).minus(yAmount).multipliedBy(100).dividedBy(yAmount)
    const aAmount = Bignumber(xAmount).multipliedBy(diffPercentage.dividedBy(100).plus(1)).decimalPlaces(0).toFixed(0)

    // Token A -> B from estimate A amount
    const convertAToBRound2 = await this.bestRateQuery.splitTwoRoutes(tokenAChecksumAddress, tokenBChecksumAddress, aAmount, convertAToB.routeIndexs, WARDEN_PERCENT_STEP, { gasLimit: DEFAULT_GAS_LIMIT_FOR_READ_METHOD })
    const aAmountInBase = utils.formatUnits(aAmount, tokenAData.decimals)

    // manage data
    const routeNameIndex0 = (await this.wardenSwap.tradingRoutes(convertAToBRound2.routeIndexs[0])).name
    const routeNameIndex1 = (await this.wardenSwap.tradingRoutes(convertAToBRound2.routeIndexs[1])).name
    convertAToBRound2.routeIndexs.forEach(async(route: any, index: number) => {
      const routeIndex = route.toString()
      const totalAmountOutForRouteInWei = convertAToBRound2.amountOut.toString()
      const totalAmountOutForRouteInWeiExcludeFee = Bignumber(totalAmountOutForRouteInWei).div(0.999).toFixed(0)
      const percentage = convertAToBRound2.volumns[index].toString()
      let splitAmountIn = ''
      let splitAmountOut = ''
      let reouteName = ''
      switch (index) {
        case 0: {
          splitAmountIn = Bignumber(percentage).div(100).multipliedBy(aAmount).toFixed(0)
          splitAmountOut = Bignumber(percentage).div(100).multipliedBy(totalAmountOutForRouteInWeiExcludeFee).toFixed(0)
          reouteName = routeNameIndex0
          break
        }
        case 1: {
          splitAmountIn = Bignumber(aAmount).minus(currentBestRate[0].amountIn).toFixed(0)
          splitAmountOut = Bignumber(totalAmountOutForRouteInWeiExcludeFee).minus(currentBestRate[0].amountOut).toFixed(0)
          reouteName = routeNameIndex1
          break
        }
      }
      currentBestRate.push({
        routeIndex: routeIndex,
        routeName: reouteName,
        amountIn: splitAmountIn,
        amountOut: splitAmountOut,
        percentage: percentage
      })
    })
    // Wei to base
    currentBestRate = currentBestRate.map(route => {
      return {
        ...route,
        amountIn: utils.formatUnits(route.amountIn, tokenAData.decimals).toString(),
        amountOut: utils.formatUnits(route.amountOut, tokenBData.decimals).toString()
      }
    })
    return { aAmountInBase, currentBestRate }
  }

  public findBestRateTokenAToTokenBFromResultManyPattern(resources: Array<any>) {
    const AmountOutSortDesc = resources.sort((a, b) => {
      return Bignumber(b.amountOut.toString()).gt(a.amountOut.toString()) ? 1 : -1
    })
    for (const resource of AmountOutSortDesc) {
      // data from splitTwoRoutes have key volumns
      if (resource.hasOwnProperty('volumns') && resource.volumns.some((volumn: any) => ['0', '100'].includes(volumn.toString()))) {
        continue
      } else {
        return resource
      }
    }
  }

  public findBestRateTokenBToTokenAFromResultManyPattern(result: Array<any>) {
    const aAmountInSortAsc = result.sort((a, b) => {
      return Bignumber(b.aAmountInBase).lt(a.aAmountInBase.toString()) ? 1 : -1
    })
    for (const p of aAmountInSortAsc) {
      if (p.currentBestRate.length > 1 && p.currentBestRate.some((s: any) => ['0', '100'].includes(s.percentage))) {
        continue
      } else {
        return p
      }
    }
  }

  public async isAllowanced(userAddr: string, tokenAddr: string, symbol: string): Promise<void> {
    await SwapModule.increaseIsAllowancedCount()
    const stampIsAllowancedCount = this.isAllowancedCount
    if (!this.isCorrectNetwork) {
      return
    }
    if (symbol === 'BNB') {
      await SwapModule.setApprovalState(ApprovalState.UNKNOWN)
      return
    }
    const etherProvider = new ethers.providers.Web3Provider(this.provider)
    const signer = etherProvider.getSigner()
    const tokenContract = new ethers.Contract(tokenAddr, ERC20_ABI, signer)
    const result = await tokenContract.allowance(userAddr, WARDEN_CONTRACT_ADDRESS, { gasLimit: DEFAULT_GAS_LIMIT_FOR_READ_METHOD })
    if (stampIsAllowancedCount !== this.isAllowancedCount) {
      return
    }
    if (result.isZero()) {
      SwapModule.setApprovalState(ApprovalState.NOT_APPROVED)
      return
    }
    SwapModule.setApprovalState(ApprovalState.APPROVED)
  }

  async approveToken(tokenAddress: string): Promise<TransactionResponse| Error> {
    try {
      const etherProvider = new ethers.providers.Web3Provider(this.provider)
      const signer = etherProvider.getSigner()
      const tokenContract = new ethers.Contract(tokenAddress, ERC20_ABI, signer)
      SwapModule.setApprovalState(ApprovalState.PENDING)
      const estimatedGas = await this.estimateGasForContract(tokenContract, 'approve', [WARDEN_CONTRACT_ADDRESS, constants.MaxUint256])
      const transactionResponse: TransactionResponse = await tokenContract.approve(WARDEN_CONTRACT_ADDRESS, constants.MaxUint256, { gasLimit: estimatedGas, gasPrice: this.gasPrice })
      return transactionResponse
    } catch (error) {
      logToRollbar('error', `Approve Token error: ${error.message}`, null)
      SwapModule.setApprovalState(ApprovalState.NOT_APPROVED)
      throw new Error(`Approve Token error: ${error.message}`)
    }
  }

  public trade(): Promise<TransactionResponse | Error> {
    SwapModule.setTradeState(TradeState.UNKNOWN)
    try {
      if (this.currentBestRate.length === 1) {
        return this.tradeOneRoute()
      }
      return this.tradeTwoRoute()
    } catch (error) {
      logToRollbar('error', 'Error: Function trade', error)
      SwapModule.setTradeState(TradeState.ERROR)
      throw error
    }
  }

  public async tradeOneRoute() {
    SwapModule.setTradeState(TradeState.PENDING)
    const tokenAChecksumAddress = getAddress(this.tokenAInput.address)
    const tokenBChecksumAddress = getAddress(this.tokenBInput.address)
    const routeIndex = this.currentBestRate[0]?.routeIndex
    const tokenAData = this.allToken.find((token: Token) => token.address === this.tokenAInput.address)
    const tokenBData = this.allToken.find((token: Token) => token.address === this.tokenBInput.address)

    console.log('Log data before wrap in args', JSON.stringify({
      routeIndex,
      tokenAChecksumAddress,
      tokenBChecksumAddress,
      'currentBestRate amount in': this.currentBestRate[0]?.amountIn,
      'currentBestRate amount out': this.currentBestRate[0]?.amountOut
    }))
    // @ts-ignore
    const amountAInWei = utils.parseUnits(this.currentBestRate[0]?.amountIn, tokenAData?.decimals)
    const amountBInWei = utils.parseUnits(this.currentBestRate[0]?.amountOut, tokenBData?.decimals)
    const minDestAmount = Bignumber(amountBInWei.toString()).times(Bignumber(100).minus(this.priceSlippage)).idiv(100).toString(10)

    const args = [
      routeIndex,
      tokenAChecksumAddress,
      amountAInWei,
      tokenBChecksumAddress,
      minDestAmount,
      WARDEN_PARTNER_INDEX
    ]

    if (this.tokenAInput.symbol === 'BNB') {
      args.push({ value: amountAInWei })
    }
    return this.callMehodTrade(this.wardenSwap, 'trade', args)
  }

  public async tradeTwoRoute() {
    SwapModule.setTradeState(TradeState.PENDING)
    // map data
    let totalAAmountInwei = '0'
    let totalBAmountInwei = '0'
    const routeIndexs: string[] = []
    const srcAmounts: string[] = []
    const tokenAChecksumAddress = getAddress(this.tokenAInput.address)
    const tokenBChecksumAddress = getAddress(this.tokenBInput.address)
    const tokenAData = this.allToken.find((token: Token) => token.address === this.tokenAInput.address)
    const tokenBData = this.allToken.find((token: Token) => token.address === this.tokenBInput.address)

    this.currentBestRate.forEach((route: any) => {
      const amountAInWei = utils.parseUnits(route.amountIn, tokenAData?.decimals).toString()
      const amountBInWei = utils.parseUnits(route.amountOut, tokenBData?.decimals).toString()
      routeIndexs.push(route.routeIndex)
      srcAmounts.push(amountAInWei)
      totalAAmountInwei = Bignumber(totalAAmountInwei).plus(amountAInWei).toString(10)
      totalBAmountInwei = Bignumber(totalBAmountInwei).plus(amountBInWei).toString(10)
    })
    const minDestAmount = Bignumber(totalBAmountInwei).times(Bignumber(100).minus(this.priceSlippage)).idiv(100).toString(10)

    // TODO: fix type of args
    // const args: (string | number | string[] | object)[] = [
    const args: any = [
      routeIndexs,
      tokenAChecksumAddress,
      totalAAmountInwei,
      srcAmounts,
      tokenBChecksumAddress,
      minDestAmount,
      WARDEN_PARTNER_INDEX
    ]
    if (this.tokenAInput.symbol === 'BNB') {
      args.push({ value: totalAAmountInwei })
    }
    return this.callMehodTrade(this.wardenSwap, 'splitTrades', args)
  }

  public setPriceSlippage(percentage: number | string) {
    SwapModule.setPriceSlippage(percentage.toString())
  }

  public async waitTransactionConfirm(transactionResponse: TransactionResponse): Promise<void> {
    SwapModule.setTradeState(TradeState.WAIT_TX_CONFIRM)
    const transactionReceipt: TransactionReceipt = await transactionResponse.wait()
    if (transactionReceipt.status === 1) {
      SwapModule.setTradeState(TradeState.SUCCERSS)
    } else {
      SwapModule.setTradeState(TradeState.FAIL)
    }
  }

  public async waitApproveTokenConfirm(transactionResponse: TransactionResponse): Promise<void> {
    const transactionReceipt: TransactionReceipt = await transactionResponse.wait()
    if (transactionReceipt.status === 1) {
      SwapModule.setApprovalState(ApprovalState.APPROVED)
    } else {
      SwapModule.setApprovalState(ApprovalState.NOT_APPROVED)
    }
  }

  private estimateGasForContract(contract: Contract, methodName: string, args: Array<any>): Promise<string| Error> {
    // @ts-ignore
    return contract.estimateGas[methodName](...args)
      .then((gasEstimate: any) => {
        return calculateGasMargin(gasEstimate.toString())
      })
      .catch((gasError: any) => {
        // TODO: For debug
        console.log('Sender address', this.userAddress)
        console.log('estimateGasForContract args', JSON.stringify(args))
        // ---------------
        logToRollbar('error', 'Error: function estimateGasForContract', gasError)
        console.error(`EstimateGas error for method ${methodName}`, gasError)
        throw new Error(`EstimateGas error for method ${methodName}`)
      })
  }

  private async callMehodTrade(contract: Contract, methodName: 'trade'| 'splitTrades', args: Array<any>): Promise<TransactionResponse| Error> {
    // @ts-ignore
    // TODO: For debug
    logToRollbar('info', 'Trading data before estimateGas', {
      senderAddress: this.userAddress,
      tokenAInputAmount: this.tokenAInput.amount,
      tokenBInputAmount: this.tokenBInput.amount,
      tokenAAddress: this.tokenAInput.address,
      tokenBAddress: this.tokenBInput.address,
      methodName,
      args: args
    })
    try {
      const estimatedGas = await this.estimateGasForContract(contract, methodName, args)
      // Add gasLimit and gasPrice in last index of array
      if (typeof args[args.length - 1] === 'object') {
        args[args.length - 1].gasLimit = estimatedGas
        args[args.length - 1].gasPrice = this.gasPrice
      } else {
        args.push({ gasLimit: estimatedGas, gasPrice: this.gasPrice })
      }
      // TODO: For debug
      logToRollbar('info', 'Trading data when callMehodTrade', {
        senderAddress: this.userAddress,
        tokenAInputAmount: this.tokenAInput.amount,
        tokenBInputAmount: this.tokenBInput.amount,
        tokenAAddress: this.tokenAInput.address,
        tokenBAddress: this.tokenBInput.address,
        methodName,
        args: args
      })
      const transactionResponse: TransactionResponse = await contract[methodName](...args)
      return transactionResponse
    } catch (error) {
      logToRollbar('error', 'Error: callMehodTrade', error)
      if (error?.code === 4001) {
        SwapModule.setTradeState(TradeState.REJECTED)
        throw new Error('Transaction rejected.')
      } else {
        SwapModule.setTradeState(TradeState.ERROR)
        console.error('Swap failed', error)
        throw new Error(`Swap failed ${error.message}`)
      }
    }
  }

  private handelBestRateQuery(type: 'fromTokenAToB' | 'fromTokenBToA') {
    if (!this.isCorrectNetwork) {
      return
    }
    switch (type) {
      case 'fromTokenAToB': {
        if (this.tokenAInput?.address && this.tokenAInput?.amount && this.tokenBInput?.address) {
          this.findBestRateTokenAToTokenB(this.tokenAInput.amount)
        }
        break
      }
      case 'fromTokenBToA': {
        if (this.tokenBInput?.address && this.tokenBInput?.amount && this.tokenAInput?.address) {
          // TODO: close feature
          // this.findBestRateTokenBToTokenA(this.tokenBInput.amount)
        } else if (
          this.tokenBInput?.address &&
          (!this.tokenBInput.hasOwnProperty('amount') || Bignumber(this.tokenBInput?.amount).isZero()) &&
          this.tokenAInput?.address &&
          this.tokenAInput?.amount
        ) {
          this.findBestRateTokenAToTokenB(this.tokenAInput.amount)
        }
        break
      }
    }
  }

  private async findBestRatefromThreePattern(tokenXtInput: TokenInput, tokenYInput: TokenInput, tokenXInputAmount: string): Promise<any> {
    const tokenXData = this.allToken.find((token: Token) => token.address === tokenXtInput.address) as Token
    const tokenYData = this.allToken.find((token: Token) => token.address === tokenYInput.address) as Token
    const tokenInputAmountInWei = utils.parseUnits(tokenXInputAmount, tokenXData.decimals)
    const tokenXChecksumAddress = getAddress(tokenXData.address)
    const tokenYChecksumAddress = getAddress(tokenYData.address)
    const wardenRoutesExpectedForOneRoute = this.getWardenRoutesExpected('oneRoute', WARDEN_ALL_ROUTE)
    const wardenRoutesExpectedForTwoRoute = this.getWardenRoutesExpected('splitTwoRoutes', WARDEN_ALL_ROUTE_FOR_SPLIT_TRADE)
    const wardenRoutesExpectedForTwoRouteV2 = this.getWardenRoutesExpected('splitTwoRoutes', WARDEN_ALL_ROUTE_FOR_SPLIT_TRADE_V2)

    const bestRateOneRoute = await this.bestRateQuery.oneRoute(tokenXChecksumAddress, tokenYChecksumAddress, tokenInputAmountInWei, wardenRoutesExpectedForOneRoute, { gasLimit: DEFAULT_GAS_LIMIT_FOR_READ_METHOD })
    let bestRateTwoRoute = null
    let bestRateTwoRouteV2 = null
    try {
      bestRateTwoRoute = await this.bestRateQuery.splitTwoRoutes(tokenXChecksumAddress, tokenYChecksumAddress, tokenInputAmountInWei, wardenRoutesExpectedForTwoRoute, WARDEN_PERCENT_STEP, { gasLimit: DEFAULT_GAS_LIMIT_FOR_READ_METHOD })
    } catch (error) {
      if (!this.isFindBestRateTwoRouteFail) {
        logToRollbar('warning', 'Find best rate for pattern bestRateTwoRoute failed', error)
        this.isFindBestRateTwoRouteFail = true
      }
      bestRateTwoRoute = { amountOut: 0, volumns: [0] }
    }
    try {
      bestRateTwoRouteV2 = await this.bestRateQuery.splitTwoRoutes(tokenXChecksumAddress, tokenYChecksumAddress, tokenInputAmountInWei, wardenRoutesExpectedForTwoRouteV2, WARDEN_PERCENT_STEP, { gasLimit: DEFAULT_GAS_LIMIT_FOR_READ_METHOD })
    } catch (error) {
      if (!this.isFindBestRateTwoRouteV2Fail) {
        logToRollbar('warning', 'Find best rate for pattern bestRateTwoRouteV2 failed', error)
        this.isFindBestRateTwoRouteV2Fail = true
      }
      bestRateTwoRouteV2 = { amountOut: 0, volumns: [0] }
    }
    return [bestRateOneRoute, bestRateTwoRoute, bestRateTwoRouteV2]
  }

  private async manageBestRateTwoRoute(tokenXtInput: TokenInput, tokenYInput: TokenInput, tokenInputAmount: string, bestRateTwoRoute: any): Promise<CurrentBestRate[]> {
    let currentBestRate: CurrentBestRate[] = []
    const tokenXData = this.allToken.find((token: Token) => token.address === tokenXtInput.address) as Token
    const tokenYData = this.allToken.find((token: Token) => token.address === tokenYInput.address) as Token
    const routeNameIndex0 = (await this.wardenSwap.tradingRoutes(bestRateTwoRoute.routeIndexs[0])).name
    const routeNameIndex1 = (await this.wardenSwap.tradingRoutes(bestRateTwoRoute.routeIndexs[1])).name

    const tokenInputAmountInWei = utils.parseUnits(tokenInputAmount, tokenXData.decimals).toString()
    bestRateTwoRoute.routeIndexs.forEach(async(route: any, index: number) => {
      const routeIndex = route.toString()
      const totalAmountOutForRouteInWei = bestRateTwoRoute.amountOut.toString()
      const percentage = bestRateTwoRoute.volumns[index].toString()
      let splitAmountIn = ''
      let splitAmountOut = ''
      let reouteName = ''
      switch (index) {
        case 0: {
          splitAmountIn = Bignumber(percentage).div(100).multipliedBy(tokenInputAmountInWei).toFixed(0)
          splitAmountOut = Bignumber(percentage).div(100).multipliedBy(totalAmountOutForRouteInWei).toFixed(0)
          reouteName = routeNameIndex0
          break
        }
        case 1: {
          splitAmountIn = Bignumber(tokenInputAmountInWei).minus(currentBestRate[0].amountIn).toFixed(0)
          splitAmountOut = Bignumber(totalAmountOutForRouteInWei).minus(currentBestRate[0].amountOut).toFixed(0)
          reouteName = routeNameIndex1
          break
        }
      }
      currentBestRate.push({
        routeIndex: routeIndex,
        routeName: reouteName,
        amountIn: splitAmountIn,
        amountOut: splitAmountOut,
        percentage: percentage
      })
    })
    // Wei to base
    currentBestRate = currentBestRate.map(route => {
      return {
        ...route,
        amountIn: utils.formatUnits(route.amountIn, tokenXData.decimals).toString(),
        amountOut: utils.formatUnits(route.amountOut, tokenYData.decimals).toString()
      }
    })
    return currentBestRate
  }

  public async getWADPrice(): Promise<any> {
    // eslint no-useless-catch: "error"
    try {
      if (!this.bestRateQuery || !this.isCorrectNetwork) {
        return
      }
      const tokenBUSDData = this.allToken.find((token: Token) => token.symbol === 'BUSD') as Token
      const tokenWADData = this.allToken.find((token: Token) => token.symbol === 'WAD') as Token
      const wadAmountInWei = utils.parseUnits('1', tokenWADData.decimals)
      // HOTFIX: for error "call revert exception"
      // Use only routes index warden pool
      const { amountOut } = await this.bestRateQuery.oneRoute(tokenWADData.address, tokenBUSDData.address, wadAmountInWei, [0, 1], { gasLimit: DEFAULT_GAS_LIMIT_FOR_READ_METHOD })
      if (amountOut) {
        const wadAmountInBase = utils.formatUnits(amountOut, tokenWADData.decimals)
        SwapModule.setWadTokenPriceUsd(wadAmountInBase)
        return wadAmountInBase
      }
      throw new Error('Error: Function getWADPrice can\'t get amountOut')
    } catch (error) {
      if (error?.data?.code === -32000 && error?.data?.message === 'header not found') {
        // bypass error from metamask when system use something not on mainnet
        return
      }
      logToRollbar('error', 'Error: Function getWADPrice', error)
      console.error(error)
    }
  }

  public async getWardenFarmInfo(): Promise<any> {
    try {
      const etherProvider = new ethers.providers.Web3Provider(this.provider)
      const ethcallProvider = new ProviderMulticall(etherProvider)
      await ethcallProvider.init()

      const compoundContract = new ContractMulticall(WARDEN_MASTER_CHEF_ADDRESS, MASTERCHEF_ABI)
      const calls = farmsConfig.map((farm) => {
        return compoundContract.pendingWarden(farm.pid, this.userAddress)
      })

      const tokenWADData = this.allToken.find((token: Token) => token.symbol === 'WAD') as Token
      const tokenContract = new ContractMulticall(tokenWADData.address, ERC20_ABI)
      calls.push(tokenContract.balanceOf(this.userAddress))

      const results = await ethcallProvider.all(calls)
      const wadTokenBalanceOfUser = utils.formatUnits(results.pop(), 18).toString()

      const earningsSum = results.reduce((accum, earning) => {
        return Bignumber(accum).plus(utils.formatUnits(earning, 18).toString())
      }, 0)
      const unclaimed = earningsSum.toString()
      const allWadToken = Bignumber(wadTokenBalanceOfUser).plus(unclaimed)

      return { unclaimed, wadTokenBalanceOfUser, allWadToken }
    } catch (error) {
      logToRollbar('error', 'Error: Function getWardenFarmInfo', error)
      console.error(error)
    }
  }

  public async addWardenTokenInMetaMask() {
    if (typeof window.ethereum !== 'undefined') {
      const tokenWADData = this.allToken.find((token: Token) => token.symbol === 'WAD') as Token
      const tokenAdded = await window.ethereum.request({
        method: 'wallet_watchAsset',
        params: {
          type: 'ERC20',
          options: {
            address: tokenWADData.address,
            symbol: tokenWADData.symbol,
            decimals: tokenWADData.decimals,
            image: WARDEN_TOKEN_IMAGE
          }
        }
      })
      return tokenAdded
    }
  }

  public swapInvertTradePrice() {
    SwapModule.setShowInvertTradePrice(!this.showInvertTradePrice)
  }

  public async getTokenPrice(tokenAddress: string) {
    if (!this.bestRateQuery || !this.isCorrectNetwork) {
      return
    }
    const tokenBusdData = this.allToken.find((token: Token) => token.symbol === 'BUSD') as Token
    const tokenDefinixData = this.allToken.find((token: Token) => token.symbol === 'FINIX') as Token
    const destinationTokenData = this.allToken.find((token: Token) => token.address === tokenAddress) as Token
    if (!destinationTokenData || !Object.keys(destinationTokenData)) {
      return
    }
    if (tokenBusdData.address === tokenAddress) {
      return '1'
    }
    const oneHundredBusd = utils.parseUnits(USD_AMOUNT_FOR_CALC_TOKEN_PRICE.toString(), tokenBusdData.decimals)
    let wardenRoutesExpected = WARDEN_ALL_ROUTE
    if (tokenAddress === tokenDefinixData.address) {
      wardenRoutesExpected = [...wardenRoutesExpected, ...SIX_FINIX_ROUTES]
    }
    const { amountOut } = await this.bestRateQuery.oneRoute(tokenBusdData.address, tokenAddress, oneHundredBusd, wardenRoutesExpected, { gasLimit: DEFAULT_GAS_LIMIT_FOR_READ_METHOD })
    const amountOutExcludeFee = Bignumber(amountOut.toString()).div(0.999).toFixed(0)
    const amountOutInBase = utils.formatUnits(amountOutExcludeFee, destinationTokenData.decimals).toString()
    const price = Bignumber(USD_AMOUNT_FOR_CALC_TOKEN_PRICE).div(amountOutInBase).toString()
    return price
  }

  public tokenInputVolumeUsd(tokenInput: TokenInput) {
    if (tokenInput?.address && tokenInput?.amount && this.tokenPrices.hasOwnProperty(tokenInput.address)) {
      const totalVolumeUsd = Bignumber(this.tokenPrices[tokenInput.address]).multipliedBy(tokenInput.amount).toString()
      return totalVolumeUsd
    }
    return ''
  }

  getVolumeUsdOfToken(tokenInput: TokenInput, amount: string) {
    if (tokenInput?.address && this.tokenPrices.hasOwnProperty(tokenInput.address)) {
      const totalVolumeUsd = Bignumber(this.tokenPrices[tokenInput.address]).multipliedBy(amount).toString()
      return totalVolumeUsd
    }
    return ''
  }

  public async calculatePriceImpact(currentBestRate: CurrentBestRate[]) {
    const result = groupTokenAmountFromBestRateData(currentBestRate)
    if (!result) {
      throw Error('System not found token amount from currentBestRate data')
    }
    if (!this.tokenPrices.hasOwnProperty(this.tokenAInput.address)) {
      const tokenAPrice = await this.handleTokenPrice(this.tokenAInput.address)
      if (!tokenAPrice) {
        throw Error('Error: Function handleTokenPrice can\'t get token price')
      }
    }
    const tokenAData = this.allToken.find((token: Token) => token.address === this.tokenAInput.address) as Token
    const tokenBData = this.allToken.find((token: Token) => token.address === this.tokenBInput.address) as Token
    const tokenAInoneHundredUsd = Bignumber(USD_AMOUNT_FOR_CALC_TOKEN_PRICE).div(this.tokenPrices[this.tokenAInput.address]).toFixed(tokenAData.decimals)
    const tokenAInOneHundredUsdInWei = ethers.utils.parseUnits(tokenAInoneHundredUsd, tokenAData.decimals)
    let oneHundredUsdTokenBAmountOutInWei = '0'

    if (result.routes.length === 1) {
      const { amountOut } = await this.bestRateQuery.oneRoute(
        this.tokenAInput.address,
        this.tokenBInput.address,
        tokenAInOneHundredUsdInWei,
        result.routes,
        { gasLimit: DEFAULT_GAS_LIMIT_FOR_READ_METHOD }
      )
      oneHundredUsdTokenBAmountOutInWei = amountOut.toString()
    } else {
      const { amountOut } = await this.bestRateQuery.splitTwoRoutes(
        this.tokenAInput.address,
        this.tokenBInput.address,
        tokenAInOneHundredUsdInWei,
        result.routes,
        WARDEN_PERCENT_STEP,
        { gasLimit: DEFAULT_GAS_LIMIT_FOR_READ_METHOD }
      )
      oneHundredUsdTokenBAmountOutInWei = amountOut.toString()
    }
    const oneHundredUsdTokenBAmountOutInWeiExcludeFee = Bignumber(oneHundredUsdTokenBAmountOutInWei).div(0.999).toFixed(0)
    const oneHundredUsdRate = Bignumber(oneHundredUsdTokenBAmountOutInWeiExcludeFee).div(Bignumber(tokenAInOneHundredUsdInWei.toString()))
    const askVolumeRate = Bignumber(ethers.utils.parseUnits(result.amountOut.toString(10), tokenBData.decimals).toString())
      .div(ethers.utils.parseUnits(result.amountIn.toString(10), tokenAData.decimals).toString())
    const calculatedPriceImpact = (askVolumeRate.minus(oneHundredUsdRate)).div(oneHundredUsdRate).multipliedBy('100')

    if (Bignumber(calculatedPriceImpact).isPositive()) {
      SwapModule.setPriceImpact('0.00')
    } else {
      const priceImpactPercent = Bignumber(calculatedPriceImpact).multipliedBy('-1').toFormat(2)
      SwapModule.setPriceImpact(priceImpactPercent)
    }
  }

  public getWardenRoutesExpected(bestRateMethodName: 'oneRoute'| 'splitTwoRoutes', initRoute: Array<number>) {
    const routesIndexExpected = [...initRoute]
    // Spacial case for FINIX token
    const tokenDefinixData = this.allToken.find((token: Token) => token.symbol === 'FINIX') as Token
    if (
      [this.tokenAInput.address, this.tokenBInput.address].includes(tokenDefinixData.address) &&
      bestRateMethodName === 'oneRoute'
    ) {
      return [...routesIndexExpected, ...SIX_FINIX_ROUTES]
    }
    // Route 15 is spartan protocol
    const tokenSpartaData = this.allToken.find((token: Token) => token.symbol === 'SPARTA') as Token
    if ([this.tokenAInput?.address, this.tokenBInput?.address].includes(tokenSpartaData.address) && bestRateMethodName === 'oneRoute') {
      routesIndexExpected.push(15)
    }

    // Route 41 is belt.fi
    if (Bignumber(this.tokenInputVolumeUsd(this.tokenAInput)).gt(20000) && bestRateMethodName === 'splitTwoRoutes') {
      routesIndexExpected.push(41)
    }
    return routesIndexExpected
  }

  private async handleTokenPrice(tokenAddress: string): Promise<string | void> {
    try {
      const price = await this.getTokenPrice(tokenAddress)
      if (price === undefined) {
        throw Error(`System not found token price for token address ${tokenAddress}`)
      }
      SwapModule.addTokenPrice({ [tokenAddress as string]: price })
      return price
    } catch (error) {
      if (!error.message.startsWith('System not found token price')) {
        logToRollbar('error', 'Error: Function handleTokenPrice', error)
      }
    }
  }

  public fetchTokenPrice() {
    const listOfTokenToGetPrice = new Set(Object.keys(this.tokenPrices))
    if (this.tokenAInput?.address) {
      listOfTokenToGetPrice.add(this.tokenAInput.address)
    }
    if (this.tokenBInput?.address) {
      listOfTokenToGetPrice.add(this.tokenBInput.address)
    }
    for (const tokenAddress of Array.from(listOfTokenToGetPrice)) {
      this.handleTokenPrice(tokenAddress)
    }
  }

  get isOverMaxPriceImpact() {
    return Bignumber(this.priceImpact).gt(MAX_PRICE_IMPACT)
  }

  get colorOfPriceImpact() {
    if (Bignumber(this.priceImpact).lt('1')) {
      return 'green'
    } else if (Bignumber(this.priceImpact).lt('3')) {
      return 'black'
    } else if (Bignumber(this.priceImpact).lt('5')) {
      return 'yellow'
    }
    return 'red'
  }
}
