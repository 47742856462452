import axios from 'axios'
import { BSC_DATASEED_URL, WARDEN_EXCHANGE_STAT_S3_URL } from '@/constants'

const bscApi = axios.create({
  baseURL: BSC_DATASEED_URL,
  headers: {
    'Content-Type': 'application/json'
  },
  timeout: 10000
})

const wardenApi = axios.create({
  baseURL: WARDEN_EXCHANGE_STAT_S3_URL,
  headers: {
    'Content-Type': 'application/json'
  },
  timeout: 10000
})
export function getBscGasPrice(): any {
  const data = { jsonrpc: '2.0', id: 1, method: 'eth_gasPrice', params: [] }
  return bscApi.post('', data)
}

export function getTotalVolume(): any {
  return wardenApi.get('/data.json')
}
