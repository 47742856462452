









































































import { Component, Prop, Ref, Watch } from 'vue-property-decorator'
import TokenListModal from '@/components/TokenListModal.vue'
import NumberAnimation from '@/components/NumberAnimation.vue'
import { AbstractSwapView } from '@/features/Swap/abstractView'
import { TokenInput as TokenInputType, ApprovalState, BestRateQuerySide } from '@/types'
import { TransactionResponse } from '@ethersproject/providers'
import { getTokenImage, handleTokenImageError, logToRollbar } from '@/utils/helper'
import Bignumber from '@/utils/bignumber'

@Component({
  name: 'TokenInput',
  components: {
    TokenListModal,
    NumberAnimation
  }
})
export default class TokenInput extends AbstractSwapView {
  @Prop({ default: '' }) readonly titleLabel!: string
  @Prop({ required: true }) readonly tokenType!: 'tokenA' | 'tokenB'
  @Prop({ default: '0' }) readonly displayBalance!: string
  @Prop({ default: '0' }) readonly displayTotalValueBalance!: string
  @Prop({ default: false }) readonly isDanger!: boolean
  @Ref() readonly TokenListModal!: TokenListModal

  // Function use in template
  getTokenImage = getTokenImage
  ApprovalState = ApprovalState
  BestRateQuerySide = BestRateQuerySide
  handleTokenImageError = handleTokenImageError

  handleSelectedToken(val: any) {
    const data: TokenInputType = { address: val.address, symbol: val.symbol }
    this.setTokenInput(this.tokenType, data)
  }

  async handleBtnApproveToken() {
    try {
      const transactionResponse = await this.approveToken(this.tokenAInput.address) as TransactionResponse
      await this.waitApproveTokenConfirm(transactionResponse)
    } catch (error) {
      logToRollbar('error', 'Error: Function handleBtnApproveToken', error)
      console.error(error)
    }
  }

  get isGreaterThanZero(): boolean {
    return Bignumber(this.displayBalance).isGreaterThan(0)
  }

  get displayTokenSymbol() {
    switch (this.tokenType) {
      case 'tokenA':
        if (this.tokenAInput?.symbol) {
          return this.tokenAInput.symbol
        }
        return ''
      case 'tokenB':
        if (this.tokenBInput?.symbol) {
          return this.tokenBInput.symbol
        }
        return ''
    }
  }

  get isDisplayLockToken(): boolean {
    if (this.tokenType === 'tokenB') {
      return false
    }
    return [ApprovalState.NOT_APPROVED, ApprovalState.PENDING].includes(this.approvalState)
  }

  get getTokenAddress() {
    switch (this.tokenType) {
      case 'tokenA':
        if (this.tokenAInput?.address) {
          return this.tokenAInput.address
        }
        return ''
      case 'tokenB':
        if (this.tokenBInput?.address) {
          return this.tokenBInput.address
        }
        return ''
    }
  }

  get totalValueUsd() {
    switch (this.tokenType) {
      case 'tokenA':
        return this.tokenInputVolumeUsd(this.tokenAInput)
      case 'tokenB':
        return this.tokenInputVolumeUsd(this.tokenBInput)
    }
  }

  get tokenInputAmount() {
    switch (this.tokenType) {
      case 'tokenA':
        if (this.tokenAInput?.amount) {
          return this.tokenAInput.amount
        }
        return ''
      case 'tokenB':
        if (this.tokenBInput?.amount) {
          return this.tokenBInput.amount
        }
        return ''
    }
  }

  set tokenInputAmount(val) {
    const valueString = val.toString(10).replace(/,/g, '')
    // @ts-ignore
    this.$parent.$refs.PercentageSelector.resetEventSelect()
    this.setTokenInputAmount(this.tokenType, valueString)
  }

  get displayPlaceholder() {
    if (this.bestRateQuerySide === BestRateQuerySide.UNKNOWN) {
      return '0.00'
    } else if (this.bestRateQuerySide === BestRateQuerySide.FROM_A_TO_B && this.tokenType === 'tokenB') {
      return 'Waiting ...'
    } else if (this.bestRateQuerySide === BestRateQuerySide.FROM_B_TO_A && this.tokenType === 'tokenA') {
      return 'Waiting ...'
    }
  }

  get isDisabledInput(): boolean {
    // Hotfix disabled feature get best rate token B -> token A
    if (this.tokenType === 'tokenB') {
      return true
    }
    // @ts-ignore
    if (this.bestRateQuerySide === BestRateQuerySide.FROM_A_TO_B && this.tokenType === 'tokenB') {
      return true
    } else if (this.bestRateQuerySide === BestRateQuerySide.FROM_B_TO_A && this.tokenType === 'tokenA') {
      return true
    }
    return false
  }

  @Watch('tokenAInput', { immediate: true, deep: true })
  async ontokenAInputChange(val: TokenInputType) {
    if (val?.address && val?.symbol && this.userAddress && this.tokenType === 'tokenA') {
      await this.isAllowanced(this.userAddress, val.address, val.symbol)
    }
  }
}
