




























































import { Component } from 'vue-property-decorator'
import { AbstractSwapView } from '@/features/Swap/abstractView'
import { getTokenImage, handleTokenImageError } from '@/utils/helper'

enum DexImagePath {
  pancake = '/svg/icon-pancake.svg',
  pancakev2 = '/svg/icon-pancake-v2.svg',
  jul = '/svg/icon-jul.svg',
  bakery = '/svg/icon-bakery.svg',
  warden = '/svg/icon-warden.svg',
  ellipsis = '/png/icon-ellipsis.png',
  valuedefi = '/png/icon-valuedefi.png',
  spartan = '/svg/icon-spartan.svg',
  acryptos = '/png/icon-acryptos.png',
  nerve = '/png/icon-nerve.png',
  definix = '/svg/icon-finix.png',
  mdex = '/png/icon-mdex.png',
  belt = '/png/icon-belt.png',
  ape = '/png/icon-ape.png',
  dop = '/png/icon-dopple.png',
  twin = '/png/icon-twindex.png',
  wault = '/png/icon-wault.png',
  dodov2 = '/svg/icon-dodo.svg',
  fat = '/png/icon-fat.png',
  panther = '/png/icon-panther.png'
}
type DexImagePathStrings = keyof typeof DexImagePath;

@Component({
  name: 'BestRateRoutingBox'
})
export default class BestRateRoutingBox extends AbstractSwapView {
  DexImagePath = DexImagePath

  getTokenImage = getTokenImage
  handleTokenImageError =handleTokenImageError

  getDexRoutes(routeName: string) {
    return routeName.split('_')?.shift()?.split('-').map(text => text?.split(':')?.shift()?.toLowerCase())
  }

  transformDexName(routeName: string) {
    switch (routeName) {
      case 'pancakev2':
        return 'Pancake V2'
      case 'dop':
        return 'Dopple'
      case 'twin':
        return 'twindex'
      case 'dodov2':
        return 'DODO V2'
      case 'fat':
        return 'FatAnimal'
      default:
        return routeName
    }
  }

  getDexImage(routeName: DexImagePathStrings) {
    try {
      return require(`@/assets${this.DexImagePath[routeName]}`)
    } catch {
      return require('@/assets/svg/icon-unknow-token.svg')
    }
  }
}
