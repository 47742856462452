import Vue from 'vue'
import { Component } from 'vue-property-decorator'
import Web3 from 'web3'
import { WalletProvider } from '../types'
import { getConnector } from '../utils'
import { Web3ConnectorState, web3ConnectorModule } from '../store'
import { Network } from '../types/Web3'
import { Web3Connector } from '../index'
import { getBscGasPrice } from '@/resources/thirdPartyApi'
import Bignumber from 'bignumber.js'
@Component
export class AbstractWeb3ConnectorView extends Vue {
  @Web3ConnectorState public readonly network!: Network | 'disconnected'

  @Web3ConnectorState public readonly walletProvider!: WalletProvider | null
  @Web3ConnectorState public readonly web3!: Web3 | null
  ethBalance: string | null = null
  @Web3ConnectorState public readonly userAddress!: string | null

  // // Wallet Info
  @Web3ConnectorState public readonly walletName!: string | null
  @Web3ConnectorState public readonly walletType!: string | null
  @Web3ConnectorState public readonly walletLogo!: string | null

  @Web3ConnectorState public readonly provider: any | null
  @Web3ConnectorState public readonly isWalletConnected!: boolean

  @Web3ConnectorState public readonly userDefaultWallet!: string
  @Web3ConnectorState public readonly isCorrectNetwork!: boolean
  @Web3ConnectorState public readonly gasPrice!: string

  WalletProvider = WalletProvider

  get web3ConstructorName(): string | null {
    return this.web3 ? this.web3.currentProvider && this.web3.currentProvider.constructor.name : null
  }

  public async connect(walletProvider: WalletProvider) {
    try {
      const connector = await getConnector(walletProvider)
      // @ts-ignore
      const web3Connector: Web3Connector = this.$web3Connector
      await web3Connector.connecTo(connector)
      this.setGasPrice()
    } catch (error) {
      console.error('connecTo wallet error', error)
      // throw error
    }
  }

  public async disconnect() {
    // @ts-ignore
    const web3Connector: Web3Connector = this.$web3Connector
    await web3Connector.disconnect()
    // this.ethBalance = ''
  }

  public async connectContractForRead() {
    // @ts-ignore
    const web3Connector: Web3Connector = this.$web3Connector
    await web3Connector.connectContractForRead()
  }

  public async sendEther(to: string, amount: string) {
    const amountToSend = this.web3!.utils.toWei(amount, 'ether')
    const result = await this.web3!.eth.sendTransaction({
      from: this.userAddress!,
      to: to,
      value: amountToSend
    })
    console.log(result)
  }

  private async setGasPrice() {
    try {
      const { data } = await getBscGasPrice()
      if (data?.result) {
        const gasPrice = new Bignumber(data.result).toString()
        web3ConnectorModule.setGasPrice(gasPrice)
      }
    } catch (error) {
      console.error('Error: Can\'t get gas price from bsc api', error)
    }
  }
}
