






































































import { Mixins, Component, Watch, Prop } from 'vue-property-decorator'
import { AbstractSwapView } from '@/features/Swap/abstractView'
import { Modal } from './modal'
import { BSC_SCAN_URL } from '@/constants'
import { TradeState } from '@/types'

@Component({
  name: 'WaitingConfirmSwapModal'
})

export default class WaitingConfirmSwapModal extends Mixins(AbstractSwapView, Modal) {
  @Prop({ default: '' }) readonly errorMessage!: string

  TradeState = TradeState
  headerText = ''
  txHash = ''

  setTxHash(txHash: any) {
    this.txHash = txHash
  }

  openLinkBSCNewTab() {
    const uri = `${BSC_SCAN_URL}/tx/${this.txHash}`
    window.open(uri, '_blank')
  }

  @Watch('isModalOpen')
  resetTxHash(val: boolean) {
    if (!val) {
      this.txHash = ''
    }
  }

  @Watch('tradeState')
  async handleTradeStateChange(status: TradeState) {
    switch (status) {
      case TradeState.PENDING:
        this.headerText = 'Waiting for confirm in your wallet'
        break
      case TradeState.WAIT_TX_CONFIRM:
        this.headerText = 'Transaction submitted'
        break
      case TradeState.SUCCERSS:
        this.headerText = 'Confirm Transaction'
        break
      case TradeState.FAIL:
        this.headerText = 'Transaction failed'
        break
      case TradeState.ERROR:
        this.headerText = 'Transaction Error'
        break
      default:
        this.headerText = ''
    }
  }
}
