
















































































































































































import { Component, Mixins, Watch, Emit } from 'vue-property-decorator'
import { AbstractSwapView } from '@/features/Swap/abstractView'
import { Modal } from './modal'
import PercentageSelector from '@/components/PercentageSelector.vue'
import { getTokenImage, handleTokenImageError, logToRollbar } from '@/utils/helper'
import { Token, TokenInput } from '@/types'
import Bignumber from '@/utils/bignumber'
import { DEFAULT_PRICE_SLIPPAGE } from '@/constants'
import { groupTokenAmountFromBestRateData } from '@/features/Swap/utils'
import { validate } from 'vee-validate'

@Component({
  name: 'ConfirmSwapModal',
  components: {
    PercentageSelector
  }
})
export default class ConfirmSwapModal extends Mixins(AbstractSwapView, Modal) {
  handleTokenImageError = handleTokenImageError

  isUserHoldWadToken = false
  SelectedToken = {}
  getTokenImage = getTokenImage
  isEligibleForFreeTrade = false
  eligibleAmount = '0'
  defaultPriceSlippage = DEFAULT_PRICE_SLIPPAGE
  tokenAAmountIn = '0'
  tokenBAmountOut = '0'
  confirmText = ''
  isValidConfirmText = false

  async handleBuyWadToken() {
    try {
      const tokenAData = this.allToken.find((token: Token) => token.address === this.tokenAInput.address) as Token
      const tokenWadData = this.allToken.find((token: Token) => token.symbol === 'WAD') as Token
      const wadPrice = await this.getTokenPrice(tokenWadData.address) as string
      const wadTokenAmount = Bignumber(this.eligibleAmount).multipliedBy(1.08).toString(10) // increase 8%
      const tokenAPrice = this.tokenPrices[this.tokenAInput.address]
      const tokenACountPerWadToken = Bignumber(wadPrice).div(tokenAPrice).toString(10)
      const tokenAAmount = Bignumber(wadTokenAmount).multipliedBy(tokenACountPerWadToken).toFixed(tokenAData.decimals)
      if (Bignumber(tokenAAmount).isNaN() || Bignumber(tokenAAmount).isZero()) {
        return
      }
      this.isModalOpen = false
      const tokenData: TokenInput = {
        address: tokenWadData.address,
        symbol: tokenWadData.symbol,
        amount: ''
      }
      await this.setTokenInput('tokenB', tokenData)
      await this.setTokenInputAmount('tokenA', tokenAAmount)
    } catch (error) {
      logToRollbar('error', 'Error: Function handleBuyWadToken', error)
    }
  }

  checkIsValidBeforeConfirmSwap() {
    if (this.isOverMaxPriceImpact && this.isValidConfirmText) {
      const result = groupTokenAmountFromBestRateData(this.currentBestRate)
      logToRollbar('info', 'User confirm swap when price is over price impact.', {
        senderAddress: this.userAddress,
        tokenAInputAmount: result.amountIn,
        tokenBInputAmount: result.amountOut,
        tokenAAddress: this.tokenAInput.address,
        tokenBAddress: this.tokenBInput.address,
        userTypingConfirm: this.confirmText
      })
      this.handleConfirmSwap()
    }
  }

  get totalValueUsdOfTokenA() {
    return this.tokenInputVolumeUsd(this.tokenAInput)
  }

  get totalValueUsdOfTokenB() {
    return this.tokenInputVolumeUsd(this.tokenBInput)
  }

  get currentBestRateVolumeUsd() {
    const result = groupTokenAmountFromBestRateData(this.currentBestRate)
    if (!result) {
      return {}
    }
    const tokenAVolumeUsd = this.getVolumeUsdOfToken(this.tokenAInput, result.amountIn)
    const tokenBVolumeUsd = this.getVolumeUsdOfToken(this.tokenBInput, result.amountOut)
    return { tokenAVolumeUsd, tokenBVolumeUsd }
  }

  @Watch('currentBestRate', { deep: true })
  async handelCurrentBestRateChange(val: any) {
    const result = groupTokenAmountFromBestRateData(val)
    if (!result) {
      return
    }
    this.tokenAAmountIn = result.amountIn
    this.tokenBAmountOut = result.amountOut
  }

  @Watch('isModalOpen')
  async setDefaultPriceSlippage(val: boolean) {
    if (val) {
      this.setPriceSlippage(this.defaultPriceSlippage)
      this.isEligibleForFreeTrade = false
      this.confirmText = ''
      this.isValidConfirmText = false
      try {
        if (this.isEligibleForFreeTrade) {
          return
        }
        this.isEligibleForFreeTrade = await this.callMethodEligibleForFreeTrade() as boolean
        this.eligibleAmount = await this.getEligibleAmount() as string
      } catch (error) {
        logToRollbar('error', 'Error: Function setDefaultPriceSlippage', error)
        console.error(error)
      }
    }
  }

  @Watch('confirmText')
  async confirmTextChange(val: string) {
    const result = await validate(val, 'required|confirmSwap')
    this.isValidConfirmText = result.valid
  }

  @Emit()
  handleConfirmSwap() {
    this.isModalOpen = false
  }
}
