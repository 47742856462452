export enum Network {
  main = 'main',
  ropsten = 'ropsten',
  rinkeby = 'rinkeby',
  goerli = 'goerli',
  kovan = 'kovan',
  bsc = 'bsc',
  bsctestnet = 'bsctestnet',
  private = 'private',
}
