export enum WalletProvider {
  INJECTED_WEB3 = 'INJECTED_WEB3',
  WALLET_CONNECT = 'WALLET_CONNECT',
  FORTMATIC = 'FORTMATIC',
  WALLET_LINK = 'WALLET_LINK',
  BINANCE_CHAIN = 'BINANCE_CHAIN'
}

export interface WalletInfo {
  provider: WalletProvider;
  name: string;
  type: string;
  logo: string;
}
