import { WalletConnector } from './types'
import { web3ConnectorModule } from './store'
import { getNetwork } from './utils'
import { ethers } from 'ethers'
import { WARDEN_CONTRACT_ADDRESS, WARDEN_BEST_RATE_QUERY_CONTRACT_ADDRESS, BSC_DATASEED_URL } from '@/constants'
import WardenSwapAbi from '@/constants/abis/WardenSwapAbi.json'
import WardenBestRateQueryAbi from '@/constants/abis/WardenBestRateQuery.json'
export class Web3Connector {
  connector: WalletConnector | null = null

  constructor() {
    this.handleChainChanged = this.handleChainChanged.bind(this)
    this.handleAccountsChanged = this.handleAccountsChanged.bind(this)
    this.handleDisconnect = this.handleDisconnect.bind(this)
  }

  public async connecTo(connector: WalletConnector) {
    this.connector = connector
    web3ConnectorModule.setWalletProvider(null)
    const { provider, web3, accounts, walletInfo } = await connector.connect()
    web3ConnectorModule.setWalletProvider(walletInfo.provider)
    web3ConnectorModule.setWeb3(web3)
    web3ConnectorModule.setUserAddress(accounts[0])
    web3ConnectorModule.setWalletName(walletInfo.name)
    web3ConnectorModule.setWalletType(walletInfo.type)
    web3ConnectorModule.setWalletLogo(walletInfo.logo)
    web3ConnectorModule.setProvider(provider)

    // Set WardenSwap
    const etherProvider = new ethers.providers.Web3Provider(provider)
    const signer = etherProvider.getSigner()

    const wardenSwap = new ethers.Contract(WARDEN_CONTRACT_ADDRESS, WardenSwapAbi, signer)
    const wardenBestRateQuery = new ethers.Contract(WARDEN_BEST_RATE_QUERY_CONTRACT_ADDRESS, WardenBestRateQueryAbi, signer)
    web3ConnectorModule.setWardenSwap(wardenSwap)
    web3ConnectorModule.setBestRateQuery(wardenBestRateQuery)

    const netId = await web3.eth.net.getId()
    const network = getNetwork(netId)
    web3ConnectorModule.setNetwork(network)
    web3ConnectorModule.setIsCorrectNetwork(this.isCorrectNetwork(network))
    web3ConnectorModule.setIsWalletConnected(true)

    this.watchWalletEvents(provider)

    return {
      web3,
      accounts,
      provider,
      walletInfo
    }
  }

  async disconnect() {
    console.log('disconnect')
    web3ConnectorModule.setNetwork('disconnected')
    web3ConnectorModule.setIsCorrectNetwork(this.isCorrectNetwork(''))
    this.unwatchWalletEvents(web3ConnectorModule.provider)
    this.clearData()
    if (web3ConnectorModule.web3 !== null) {
      this.connector!.disconnect()
    }
    this.connectContractForRead()
  }

  async connectContractForRead() {
    // Set WardenSwap
    const etherProvider = new ethers.providers.JsonRpcProvider(BSC_DATASEED_URL)

    const wardenSwap = new ethers.Contract(WARDEN_CONTRACT_ADDRESS, WardenSwapAbi, etherProvider)
    const wardenBestRateQuery = new ethers.Contract(WARDEN_BEST_RATE_QUERY_CONTRACT_ADDRESS, WardenBestRateQueryAbi, etherProvider)
    web3ConnectorModule.setIsCorrectNetwork(true)
    web3ConnectorModule.setWardenSwap(wardenSwap)
    web3ConnectorModule.setBestRateQuery(wardenBestRateQuery)
  }

  clearData() {
    web3ConnectorModule.setWalletProvider(null)
    web3ConnectorModule.setWeb3(null)
    web3ConnectorModule.setUserAddress(null)
    web3ConnectorModule.setWalletName(null)
    web3ConnectorModule.setWalletType(null)
    web3ConnectorModule.setWalletLogo(null)
    web3ConnectorModule.setProvider(null)
    web3ConnectorModule.setIsWalletConnected(false)
  }

  watchWalletEvents(provider: any) {
    // Subscribe to accounts change
    if (provider && provider.on) {
      provider.on('accountsChanged', this.handleAccountsChanged)

      // Subscribe to chainId change
      provider.on('chainChanged', this.handleChainChanged)

      // Subscribe to provider disconnection
      provider.on('disconnect', this.handleDisconnect)
    }
  }

  private handleChainChanged(chainId: string): void {
    console.log("Handling 'chainChanged' event with payload", chainId)
    const newtork = getNetwork(parseInt(chainId))
    web3ConnectorModule.setNetwork(newtork)
    web3ConnectorModule.setIsCorrectNetwork(this.isCorrectNetwork(newtork))
  }

  private handleAccountsChanged(accounts: string[]): void {
    console.log("Handling 'accountsChanged' event with payload", accounts)
    if (accounts[0] === undefined) {
      this.clearData()
      this.connectContractForRead()
    }
    web3ConnectorModule.setUserAddress(accounts[0])
  }

  private handleDisconnect(): void {
    console.log("Handling 'disconnect' event")
    this.disconnect()
  }

  private isCorrectNetwork(network: string): boolean {
    const CHAIN_ID = process.env.VUE_APP_CHAIN_ID !== undefined ? parseInt(process.env.VUE_APP_CHAIN_ID) : null
    if (CHAIN_ID && getNetwork(CHAIN_ID) === network) {
      return true
    }
    return false
  }

  unwatchWalletEvents(provider: any) {
    if (provider && provider.removeListener) {
      if (provider.stop) {
        provider.stop()
      }
      provider.removeListener('accountsChanged', this.handleAccountsChanged)
      provider.removeListener('chainChanged', this.handleChainChanged)
      provider.removeListener('disconnect', this.handleDisconnect)
    }
  }
}
