





























































import { Component, Prop } from 'vue-property-decorator'
import { AbstractSwapView } from '@/features/Swap/abstractView'
import { Token, UserLocalStorage } from '@/types'
import {
  getTokenImage,
  handleTokenImageError,
  precisionNumber,
  numberWithCommas,
  loadUserStorage,
  addTokenInlocalStorage,
  removeTokenInlocalStorage
} from '@/utils/helper'
import tokenList from '@/constants/token/wardenswap.json'

@Component({
  name: 'TokenDetailWrap'
})
export default class TokenDetailWrap extends AbstractSwapView {
  @Prop() readonly token!: Token
  getTokenImage = getTokenImage
  handleTokenImageError = handleTokenImageError
  currentUserStorage = {} as UserLocalStorage

  created() {
    this.currentUserStorage = loadUserStorage()
  }

  // TODO: change name
  get isHasData(): boolean {
    if (this.token?.address && this.tokensBalance.hasOwnProperty(this.token.address)) {
      return true
    }
    return false
  }

  get isCustomToken(): boolean {
    return !tokenList.some(token => token.address.toLowerCase() === this.token?.address?.toLowerCase())
  }

  get isTokenInLocalStorage(): boolean {
    if (this.currentUserStorage?.tokens.hasOwnProperty(this.token.address as string)) {
      return true
    }
    return false
  }

  handleAddTokenInLocalStorage() {
    const tokenData: Token = {
      name: this.token.name,
      symbol: this.token.symbol,
      address: this.token.address,
      decimals: this.token.decimals
    }
    if (!this.allToken.some((token: Token) => token?.address === this.token.address)) {
      this.increaseTokenInState(tokenData)
    }
    addTokenInlocalStorage(tokenData)
    this.currentUserStorage = loadUserStorage()
    this.getTokensBalance()
  }

  handleRemoveTokenInLocalStorage() {
    this.decreaseTokenInState(this.token.address)
    removeTokenInlocalStorage(this.token.address)
    this.currentUserStorage = loadUserStorage()
  }

  displayTokensBalance(tokenAddress: any) {
    return numberWithCommas((precisionNumber(this.tokensBalance[tokenAddress], 6)).toString(10))
  }
}
