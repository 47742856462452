import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from '@/store'
import { Web3Connector } from '@/features/Web3Connector'
import { rollbar, logToRollbar } from '@/utils/helper'
import PrimeVue from 'primevue/config'
import Dialog from 'primevue/dialog'
import Button from 'primevue/button'
import Sidebar from 'primevue/sidebar'
import ToastService from 'primevue/toastservice'
import Skeleton from 'primevue/skeleton'
import Tooltip from 'primevue/tooltip'
import Ripple from 'primevue/ripple'
import Galleria from 'primevue/galleria'
import Checkbox from 'primevue/checkbox'
import { ValidationProvider } from 'vee-validate'
import VueCarousel from 'vue-carousel'

// @ts-ignore
import Jazzicon from 'vue-jazzicon'
import VueClipboard from 'vue-clipboard2'

// Style
import '@/assets/scss/main.scss'
import '@/assets/scss/tailwind.scss'
import 'primevue/resources/themes/saga-blue/theme.css'
import 'primevue/resources/primevue.min.css'
import 'primeicons/primeicons.css'

// utils
import '@/utils/viewFilter'
import '@/utils/veeValidate'

Vue.config.productionTip = false

Vue.use(PrimeVue, { ripple: true })
Vue.component('Dialog', Dialog)
Vue.component('Button', Button)
Vue.component('Sidebar', Sidebar)
Vue.component('Skeleton', Skeleton)
Vue.component('Galleria', Galleria)
Vue.component('Checkbox', Checkbox)
Vue.directive('tooltip', Tooltip)
Vue.directive('ripple', Ripple)
Vue.component('jazzicon', Jazzicon)
Vue.use(ToastService)
Vue.use(VueClipboard)
Vue.use(VueCarousel)
Vue.component('ValidationProvider', ValidationProvider)

Vue.prototype.$rollbar = rollbar
Vue.prototype.$web3Connector = new Web3Connector()

Vue.config.errorHandler = (err) => {
  logToRollbar('error', null, err)
  throw err // rethrow
}

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
