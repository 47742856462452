







































import { Component, Ref } from 'vue-property-decorator'
import { AbstractWeb3ConnectorView } from '@/features/Web3Connector/abstractView'
import ConnectWalletModal from '@/components/ConnectWalletModal.vue'
import UserWalletModal from '@/components/UserWalletModal.vue'

@Component({
  name: 'ConnectWalletButtonOnMobile',
  components: {
    ConnectWalletModal,
    UserWalletModal
  }
})

export default class ConnectWalletButtonOnMobile extends AbstractWeb3ConnectorView {
  @Ref() readonly UserWalletModal!: UserWalletModal
}
